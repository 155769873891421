import { Box, Grid, Typography } from '@suid/material';
import { createEffect, createSignal } from 'solid-js';
import { featureFlagStore } from '@store/featureFlags/store';
import {
  fetchLtlQuoteTerminals,
  LtlTerminalInfoViewModel,
} from '@store/orders';

import TerminalDestinationCard from './components/TerminalDestinationCard';

export const ViewTerminalPopover = (props: {
  terminalInfo: LtlTerminalInfoViewModel;
  quoteId: string;
}) => {
  const [terminalInfo, setTerminalInfo] =
    createSignal<LtlTerminalInfoViewModel>(props.terminalInfo);

  createEffect(async () => {
    if (
      featureFlagStore.flags['isReadFromNewLtlTables'].enabled &&
      props.quoteId
    ) {
      const terminals = await fetchLtlQuoteTerminals(String(props.quoteId));
      setTerminalInfo(terminals);
    }
  });

  return (
    <Box class="max-w-[600px]">
      <Typography variant="h4" class="text-center">
        Terminal Info
      </Typography>
      <Grid container spacing={2} class="p-4">
        <Grid item xs={6}>
          <Typography variant="h6">Origin</Typography>
          <TerminalDestinationCard {...terminalInfo().pickupTerminal} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Destination</Typography>
          <TerminalDestinationCard {...terminalInfo().deliveryTerminal} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewTerminalPopover;
