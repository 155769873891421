import toast, { Toast } from 'solid-toast';
import { printLog } from '@utils/utils';
import { Info, Warning } from '@suid/icons-material';

import {
  CustomToast,
  CustomToastWithHoverPause,
  InboxToast,
  MultiLineToast,
  WithTimerToast,
} from './CustomToasts';

export type OpenToastProps = {
  message: string;
  label?: string;
  position?: ToastPosition;
  type?: ToastType;
  title?: string;
  duration?: number;
};

export enum ToastType {
  Success = 'Success',
  Error = 'Error',
  Info = 'Info',
  Loading = 'Loading',
  Custom = 'Custom',
  MultiLine = 'MultiLine',
  Inbox = 'Inbox',
  WithTimer = 'WithTimer',
  Caution = 'Caution',
}

export type ToastPosition =
  | 'top-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-center'
  | 'top-left';

const defaultToastOptions = {
  duration: 10000,
  style: { 'max-width': '700px' },
  position: 'top-right' as ToastPosition,
};

export const openToast = ({
  type,
  position = defaultToastOptions.position,
  message,
  duration = defaultToastOptions.duration,
  title,
}: OpenToastProps) => {
  switch (type) {
    case ToastType.Success:
      toast.success(
        (t) => (
          <CustomToastWithHoverPause
            message={message}
            onClose={() => toast.dismiss(t.id)}
            t={t}
            duration={duration}
            title={title}
          />
        ),
        {
          position,
          duration,
          style: defaultToastOptions.style,
        },
      );
      break;

    case ToastType.Error:
      toast.error(
        (t) => (
          <CustomToastWithHoverPause
            message={message}
            onClose={() => toast.dismiss(t.id)}
            t={t}
            duration={duration}
          />
        ),
        {
          duration,
          position,
          style: defaultToastOptions.style,
        },
      );
      break;
    case ToastType.Info:
      toast(
        (t) => (
          <CustomToastWithHoverPause
            message={message}
            onClose={() => toast.dismiss(t.id)}
            t={t}
            duration={duration}
          />
        ),
        {
          duration,
          icon: <Info style={{ color: '#2D9CDB' }} />,
          position,
          style: defaultToastOptions.style,
        },
      );
      break;
    case ToastType.Loading:
      toast.loading(message, {
        duration,
        position,
      });
      break;

    case ToastType.Caution:
      toast(
        (t) => (
          <CustomToastWithHoverPause
            message={message}
            onClose={() => toast.dismiss(t.id)}
            t={t}
            duration={duration}
          />
        ),
        {
          duration,
          icon: <Warning style={{ color: '#F59D25' }} />,
          position,
          style: defaultToastOptions.style,
        },
      );
      break;

    case ToastType.Custom:
      toast.custom(
        (t: Toast) => (
          <CustomToast
            message={message}
            onClose={() => printLog('clear')}
            visible={t.visible}
          />
        ),
        {
          duration,
          position,
        },
      );
      break;

    case ToastType.MultiLine:
      toast.custom(() => <MultiLineToast message={message} />, {
        duration,
        position,
      });
      break;

    case ToastType.Inbox:
      toast.custom(
        (t) => (
          <InboxToast
            title={title ?? 'Inbox Message'}
            message={message}
            onClose={() => toast.dismiss(t.id)}
          />
        ),
        { duration, position },
      );
      break;

    case ToastType.WithTimer:
      toast.custom(
        (t) => (
          <WithTimerToast
            message={message}
            duration={duration}
            onClose={() => toast.dismiss(t.id)}
            visible={t.visible}
            id={t.id}
            title={title ?? 'Title'}
          />
        ),
        { duration, position },
      );
      break;

    default:
      toast(message, { duration, position }); // Default case for simple text toast
      break;
  }
};
