import { calculateMargin } from '@common/utils';
import { Popover } from '@components';
import { Button } from '@components/Button';
import { domainUrl } from '@constants/urls';
import { A, useNavigate } from '@solidjs/router';
import { openDialogBox } from '@store/dialogBox';
import { LTLRateQuoteRequest, LTLRateQuoteResponse } from '@store/ltl';
import { saveTempQuote } from '@store/ltl/services';
import { ltlRateQuoteRequest } from '@store/ltl/store';
import { LTLStoreQuoteResponse } from '@store/ltl/types';
import { closeModal } from '@store/modals';
import { mergeOrderWithQuote, orderStore } from '@store/orders';
import { Delete, InfoOutlined } from '@suid/icons-material';
import CheckIcon from '@suid/icons-material/Check';
import ReceiptLong from '@suid/icons-material/ReceiptLong';
import SaveAsIcon from '@suid/icons-material/SaveAs';
import { Box, Grid, Paper, Stack, Typography } from '@suid/material';
import { routeOrderDetailsQuote } from '@utils/routeOrderDetails';
import { formatAmount } from '@utils/utils';
import { AgGridSolidRef } from '@ag-grid-community/solid';
import { Show, createSignal } from 'solid-js';
import { featureFlagStore } from '@store/featureFlags/store';

import ViewCostDetailsPopover from '../ViewCostDetailsPopover';
import ViewTerminalPopover from '../ViewTerminalPopover';
import { quoteDetailsRowRenderClasses as cls } from './classes';

interface QuoteProps {
  data: LTLRateQuoteResponse;
  request?: LTLRateQuoteRequest;
  isGetQuote?: boolean;
  type?: string;
  quoteStatus?: string;
  customerId?: number;
  id: string;
  gridRef?: AgGridSolidRef;
  setRowId: (id: string) => void;
}
const defaultProps = {
  minWidth: '1000px',
  width: '90%',
};

export const QuoteStatus = (quoteStatus: string) => {
  const statusColorMap: { [key: string]: string } = {
    Active: 'bg-[#31637F]',
    'Load Created': 'bg-[#31637F]',
    Expired: 'bg-[#F59D25]',
    Deleted: 'bg-[#B00020]',
  };
  const statusColor = statusColorMap[quoteStatus] || 'bg-[#31637F]';
  return <span class={`${cls.statusBtn} ${statusColor}`}>{quoteStatus}</span>;
};

export const QuoteDetailsRowRender = (props: QuoteProps) => {
  const [saveQuoteButton, setSaveQuoteButton] = createSignal<boolean>(false);
  const [savedQuoteResponse, setSavedQuoteResponse] =
    createSignal<LTLStoreQuoteResponse | null>(null);
  const navigate = useNavigate();

  return (
    <Box p={2} class={Boolean(props.isGetQuote) ? '' : cls.cardContainer}>
      <Paper
        elevation={3}
        sx={
          Boolean(props.isGetQuote)
            ? {
                width: '100%',
              }
            : defaultProps
        }
      >
        <Box sx={{ flexGrow: 1 }} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="body1" class="!font-medium">
                {props.data.shipper}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box class={cls.cardHeaderRight}>
                {props.type === 'history' && (
                  <Typography variant="body1" class={cls.StatusTextContainer}>
                    <span class={cls.marginText}>Status: </span>
                    {QuoteStatus(props.quoteStatus as string)}
                  </Typography>
                )}
                <Popover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  eleName={
                    <Typography
                      variant="body1"
                      class={cls.viewTerminalInfoLink}
                    >
                      <A href="#">View Terminal Info</A>
                    </Typography>
                  }
                >
                  <ViewTerminalPopover
                    terminalInfo={{ ...props.data.terminalInfo }}
                    quoteId={String(props.data.id)}
                  />
                </Popover>
                <Popover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  eleName={
                    <Typography variant="body1" class={cls.costDetailsLink}>
                      <InfoOutlined class={cls.costDetailsInfoIcon} />
                      Cost Details
                    </Typography>
                  }
                >
                  <ViewCostDetailsPopover data={props.data} />
                </Popover>
                <Typography variant="body1" class={cls.marginTextContainer}>
                  {props.customerId !== undefined && props.customerId !== 0 ? (
                    <span class={cls.marginText}>
                      Margin:{''}
                      {calculateMargin(
                        props.data.customerRateQuote?.totalCost ?? 0,
                        props.data.totalCost,
                      )}
                      {' %'}
                    </span>
                  ) : (
                    ''
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={2.5} class={cls.logoBox}>
              <img
                src={`${domainUrl}${props.data.logoImage}`}
                alt="carrier logo"
                class={cls.carrierLogo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Grid container spacing={1} margin={0}>
                  <Grid item xs={12} md={7}>
                    <Typography variant="body1" class={cls.labelText}>
                      Carrier Quote ID
                    </Typography>
                    <Typography variant="body1" class={cls.infoText}>
                      {props.data.quoteID}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography variant="body1" class={cls.labelText}>
                      Est. Transit Days
                    </Typography>
                    <Typography variant="body1" class={cls.infoText}>
                      {props.data.transitDays}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={7}>
                    <Typography variant="body1" class={cls.labelText}>
                      MovementType
                    </Typography>
                    <Typography variant="body1" class={cls.infoText}>
                      {props.data.movementType ?? '--'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography variant="body1" class={cls.labelText}>
                      Est. Delivery Date
                    </Typography>
                    <Typography variant="body1" class={cls.infoText}>
                      {Boolean(props.data.estimatedDeliveryDate)
                        ? new Date(
                            props.data.estimatedDeliveryDate as string,
                          ).toLocaleDateString()
                        : '--'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={7}>
                    <Typography variant="body1" class={cls.labelText}>
                      Service Type
                    </Typography>
                    <Typography variant="body1" class={cls.infoText}>
                      {props.data.serviceType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography variant="body1" class={cls.labelText}>
                      Max Carrier Liability
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      class={cls.liabilityText}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                        class={cls.infoTextBold}
                      >
                        New:
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        class={cls.infoText}
                      >
                        {formatAmount(props.data.maxNewLiability!)}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        class={cls.infoTextBold}
                      >
                        Used:
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        class={cls.infoText}
                      >
                        {formatAmount(props.data.maxUsedLiability!)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3.5}>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    component="p"
                    class={cls.amountHeading}
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box class={cls.amountDetailsContainer}>
                    <Box>
                      <Typography variant="body1" class={cls.carrierAmountText}>
                        {formatAmount(props.data.totalCost)}
                      </Typography>
                      <Typography variant="body1" class={cls.labelText}>
                        Carrier
                      </Typography>
                    </Box>
                    {Boolean(props.data.customerRateQuote) && (
                      <Box class="!pl-3">
                        <Typography
                          variant="body1"
                          class={cls.carrierAmountText}
                        >
                          {formatAmount(
                            props.data.customerRateQuote?.totalCost,
                          )}
                        </Typography>
                        <Typography variant="body1" class={cls.labelText}>
                          Customer
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Show when={Boolean(props.isGetQuote)}>
                  <Grid
                    item
                    xs={12}
                    displayRaw="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    <Button
                      variant="contained"
                      startIcon={<CheckIcon />}
                      class="!bg-[#248670]"
                      onClick={async () => {
                        let ltlQuoteId;
                        if (saveQuoteButton()) {
                          ltlQuoteId = savedQuoteResponse()?.correlationId;
                        } else {
                          const response = await saveTempQuote({
                            quoteResponse: props.data,
                            customerId: props.customerId,
                            requestDetails: props.request,
                          });
                          const data = response as LTLStoreQuoteResponse;
                          ltlQuoteId = data.correlationId;
                        }

                        if (orderStore.order.id !== 0) {
                          closeModal('ltl-quoting-modal');
                          // navigate to order details page with orderid and correlationId
                          await mergeOrderWithQuote(
                            String(ltlQuoteId),
                            orderStore.order,
                          );
                        } else {
                          const isNative = routeOrderDetailsQuote(
                            String(ltlQuoteId),
                            null,
                          );
                          if (isNative) {
                            const path = `/order/details/?ltlQuoteId=${ltlQuoteId}`;
                            navigate(path);
                          }
                        }
                      }}
                      label="Select Quote"
                    />
                    {featureFlagStore.flags['isWriteToOldLtlTables']
                      .enabled && (
                      <Show when={Boolean(props.isGetQuote)}>
                        <Button
                          disabled={saveQuoteButton()}
                          variant="contained"
                          startIcon={<SaveAsIcon />}
                          label={saveQuoteButton() ? 'Saved' : 'Save Quote'}
                          onClick={() => {
                            void saveTempQuote({
                              requestDetails: ltlRateQuoteRequest.items,
                              customerId: props.customerId,
                              quoteResponse: props.data,
                            }).then((resp) => {
                              if (Boolean(resp)) {
                                setSavedQuoteResponse(
                                  resp as LTLStoreQuoteResponse,
                                );
                                setSaveQuoteButton(true);
                              }
                            });
                          }}
                        />
                      </Show>
                    )}
                  </Grid>
                </Show>
                <Show when={!Boolean(props.isGetQuote)}>
                  <Show when={props.type !== 'history'}>
                    <Grid
                      item
                      xs={12}
                      displayRaw="flex"
                      justifyContent="center"
                      flexWrap="wrap"
                      gap={2}
                    >
                      <Button
                        variant="contained"
                        startIcon={<ReceiptLong />}
                        onClick={() => {
                          const isNative = routeOrderDetailsQuote(
                            String(props.data.lTLQuoteId),
                            String(props.data.id),
                          );
                          if (isNative) {
                            const path = `/order/details/?ltlQuoteId=${props.data.lTLQuoteId}`;
                            navigate(path);
                          }
                        }}
                        label="Create Order"
                      />
                      <Button
                        variant="contained"
                        startIcon={<Delete />}
                        class="!bg-[#B00020]"
                        label="Delete"
                        onClick={() => {
                          props.setRowId(props.id);
                          openDialogBox('deleteSavedQuote');
                        }}
                      />
                    </Grid>
                  </Show>
                </Show>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
