import { BasicModal, ToastType } from '@components';
import { TextInput } from '@components/forms';
import { closeModal } from '@store/modals';
import { createSignal } from 'solid-js';
import { Button } from '@components/Button';
import { Grid } from '@suid/material';
import { apiClient } from '@api/apiClient';
import { datadogRum } from '@datadog/browser-rum';
import { handleToast } from '@utils/utils';
import { apiCallsStore } from '@store/api/store';
import { V4ApiResponse } from '@store/customers/customerDetails';

type CreateJiraSupportTicketModel = {
  IssueText: string;
  RumSessionId: string;
  CurrentUrl: string;
  ApiCalls: string[];
};

export const SupportModal = () => {
  const [desc, setDesc] = createSignal<string>('');
  const [loading, setLoading] = createSignal<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const rumContext = datadogRum.getInternalContext();

      const requestObject: CreateJiraSupportTicketModel = {
        IssueText: desc().trim(),
        RumSessionId:
          rumContext?.session_id == null ? '' : rumContext.session_id,
        CurrentUrl: window.location.href,
        ApiCalls: apiCallsStore.apiCalls.map((a) => {
          return `Url: ${a.url}, Method: ${a.method}, StatusCode: ${a.status}, Time: ${a.timeStamp}`;
        }),
      };

      const result: V4ApiResponse<null> = await apiClient.post(
        'Support/SubmitSupportTicket',
        requestObject,
      );

      if (Boolean(result.isSuccess)) {
        handleToast(
          ToastType.Success,
          'Your support ticket was successfully submitted! Please contact ithelpdesk@armstrongtransport for additional support.',
          `Ticket # ${result.value}`,
        );
        setLoading(false);
        closeModal('SupportModal');
        setDesc('');
      } else {
        handleToast(
          ToastType.Error,
          'We were unable to submit your support ticket. Please try again, or contact ithelpdesk@armstrongtransport for assistance.',
        );
        setLoading(false);
      }
    } catch {
      handleToast(
        ToastType.Error,
        'We were unable to submit your support ticket. Please try again, or contact ithelpdesk@armstrongtransport for assistance.',
      );
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    setDesc('');
    closeModal('SupportModal');
  };
  return (
    <BasicModal
      id={'SupportModal'}
      title="Request Support"
      footer={false}
      onClose={onCloseModal}
      showClose={false}
      width="25vw"
      modalStyles={{
        background: 'white',
        borderRadius: '5px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            label="Issue Description"
            value={desc()}
            multiline
            rows={3}
            maxLength={1000}
            name="Issue Description"
            error={desc().length > 1000}
            disabled={loading()}
            onChange={(v: string) => {
              setDesc(v);
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <Button
            isLoading={loading()}
            variant="contained"
            label="Submit Support Ticket"
            type="button"
            disabled={!desc().trim()}
            onClick={onSubmit}
          />
          <Button
            class="float-right"
            variant="text"
            label="Cancel"
            type="button"
            onClick={() => {
              closeModal('SupportModal');
            }}
          />
        </Grid>
      </Grid>
    </BasicModal>
  );
};
