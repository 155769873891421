import { Box, Grid } from '@suid/material';
import { Component } from 'solid-js';
import AddressAutoComplete, {
  AddressDetails,
} from '@components/Autocomplete/AddressAutoComplete';
import {
  factoringCompanyDetailsStore,
  updateFactoringCompanyDetailsState,
} from '@store/factoringCompany/factoringCompanyDetails';
import { TextInput } from '@components/forms';
import { Button, Typography } from '@components';

import { validateFieldForFactoring } from './utils';

type FactoringCompanyDetailsAddressProps = {
  addressType: 'mailing' | 'physical';
};

export const FactoryCompanyDetailsAddress: Component<
  FactoringCompanyDetailsAddressProps
> = (props) => {
  const validateAddressFields = async () => {
    const fields = ['Address1', 'City', 'State', 'Zip'];

    for (const field of fields) {
      await validateFieldForFactoring(
        `${props.addressType}${field}`,
        `${props.addressType}${field}`,
      );
    }
  };

  const handleGooglePlacesSelect = async (place: AddressDetails) => {
    const { address1 = '', city = '', state = '', zipCode = '' } = place;
    updateFactoringCompanyDetailsState({
      [`${props.addressType}Address1`]: address1,
      [`${props.addressType}City`]: city,
      [`${props.addressType}State`]: state,
      [`${props.addressType}Zip`]: zipCode,
    });
    await validateAddressFields();
  };

  const handleSetSameAsAddress = async () => {
    updateFactoringCompanyDetailsState({
      [`${props.addressType}Address1`]:
        factoringCompanyDetailsStore.factoringCompanyDetails[
          `${props.addressType === 'mailing' ? 'physical' : 'mailing'}Address1`
        ] ?? '',
      [`${props.addressType}Address2`]:
        factoringCompanyDetailsStore.factoringCompanyDetails[
          `${props.addressType === 'mailing' ? 'physical' : 'mailing'}Address2`
        ] ?? '',
      [`${props.addressType}City`]:
        factoringCompanyDetailsStore.factoringCompanyDetails[
          `${props.addressType === 'mailing' ? 'physical' : 'mailing'}City`
        ] ?? '',
      [`${props.addressType}State`]:
        factoringCompanyDetailsStore.factoringCompanyDetails[
          `${props.addressType === 'mailing' ? 'physical' : 'mailing'}State`
        ] ?? '',
      [`${props.addressType}Zip`]:
        factoringCompanyDetailsStore.factoringCompanyDetails[
          `${props.addressType === 'mailing' ? 'physical' : 'mailing'}Zip`
        ] ?? '',
    });
    await validateAddressFields();
  };

  const handleChange = async (key: string, value: string) => {
    updateFactoringCompanyDetailsState({
      [`${props.addressType}${key}`]: value,
    });
    await validateFieldForFactoring(
      `${props.addressType}${key}`,
      `${props.addressType}${key}`,
    );
  };

  const shouldButtonDisable = () => {
    return !Boolean(
      factoringCompanyDetailsStore.factoringCompanyDetails[
        `${props.addressType === 'mailing' ? 'physical' : 'mailing'}Address1`
      ],
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        pt: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            displayRaw="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              component="h6"
              class="
            !text-[#1B4960]
            text-xl
            "
            >
              {props.addressType === 'mailing'
                ? 'Mailing Address'
                : 'Physical Address'}
            </Typography>
            <Button
              label={`Same as ${
                props.addressType === 'mailing' ? 'Physical' : 'Mailing'
              }`}
              variant="contained"
              onClick={handleSetSameAsAddress}
              disabled={shouldButtonDisable()}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AddressAutoComplete
            label={
              <span>
                Address line 1<sup>*</sup>
              </span>
            }
            placeholder="Enter Address"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails[
                `${props.addressType}Address1`
              ] ?? ''
            }
            onChange={(val) => handleChange('Address1', val)}
            onItemSelect={async (item) => {
              await handleGooglePlacesSelect(item);
            }}
            zIndex="9999"
            error={
              factoringCompanyDetailsStore.factoringCompanyDetailsErrors?.[
                `${props.addressType}Address1`
              ]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            maxLength={50}
            label="Address line 2"
            placeholder="Enter Address"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails[
                `${props.addressType}Address2`
              ] ?? ''
            }
            onChange={(val) => handleChange('Address2', val as string)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextInput
                label="City"
                value={
                  factoringCompanyDetailsStore.factoringCompanyDetails[
                    `${props.addressType}City`
                  ] ?? ''
                }
                onChange={(val) => handleChange('City', val as string)}
                error={
                  factoringCompanyDetailsStore.factoringCompanyDetailsErrors?.[
                    `${props.addressType}City`
                  ]
                }
                maxLength={50}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextInput
                label="State"
                maxLength={2}
                value={
                  factoringCompanyDetailsStore.factoringCompanyDetails[
                    `${props.addressType}State`
                  ] ?? ''
                }
                onChange={(val) => handleChange('State', val as string)}
                error={
                  factoringCompanyDetailsStore.factoringCompanyDetailsErrors?.[
                    `${props.addressType}State`
                  ]
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextInput
                label="Zip"
                value={
                  factoringCompanyDetailsStore.factoringCompanyDetails[
                    `${props.addressType}Zip`
                  ] ?? ''
                }
                onChange={(val) => handleChange('Zip', val as string)}
                error={
                  factoringCompanyDetailsStore.factoringCompanyDetailsErrors?.[
                    `${props.addressType}Zip`
                  ]
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
