/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { apiClient } from '@api/apiClient';
import { Typography } from '@components';
import { LoadBoardEventEnum, LoadBoardWSMsgT } from '@store/loadboard';
import {
  GetForceUnlockOrderLockFeatureFlag,
  GetOrderLockFeatureFlag,
  orderStore,
  setOrderLockedBy,
  setOrderStore,
  setOrderViewedBy,
  UserDataViewLocksComposite,
} from '@store/orders';
import {
  Key,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Lock,
  Refresh,
  Visibility,
  Warning,
} from '@suid/icons-material';
import { Box, Button, Skeleton, Stack } from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { printLog } from '@utils/utils';
import { DateTime, Duration } from 'luxon';
import {
  createEffect,
  createRoot,
  createSignal,
  onCleanup,
  onMount,
  Show,
} from 'solid-js';

import { globalState } from '../../../../store/Global/store';
import { userStore } from '../../../../store/user/store';
import './UsersViewingComponent.css';
import { Container } from './UsersViewingContainer';

const userViewingMessages: Record<string, boolean> = {};

export const UsersViewingComponent = (props: { orderId: string }) => {
  return createRoot(() => {
    const [getUsersViewing, setUsersViewing] = createSignal('');
    const [getUserDataViewLocksComposite, setUserDataViewLocksComposite] =
      createSignal<UserDataViewLocksComposite | null>(null);
    const [socketHandlerInitialized, setSocketHandlerInitialized] =
      createSignal(false);
    const [getIsFirstMount, setIsFirstMount] = createSignal(false);
    const [countdown, setCountdown] = createSignal('');
    const [isManualLock, setIsManualLock] = createSignal(false);
    const [isMessageOpen, setIsMessageOpen] = createSignal(false);
    const [isOrderLockEnabled, setIsOrderLockEnabled] = createSignal(
      orderStore.orderLockEnabled != undefined && orderStore.orderLockEnabled,
    );
    const [loggedInUserId, setLoggedInUserId] = createSignal(
      userStore.user.id ? userStore.user.id : -1,
    );

    createEffect(() => {
      setIsOrderLockEnabled(
        orderStore.orderLockEnabled != undefined && orderStore.orderLockEnabled,
      );
    });

    const getUserData = async () => {
      const usersData = (await apiClient.post(
        `order/viewing/${props.orderId}`,
        {},
      )) as unknown as UserDataViewLocksComposite;

      setUserDataViewLocksComposite(usersData);

      return usersData;
    };

    const fetchUsersAndLock = async () => {
      try {
        if (!props.orderId || props.orderId === '0') {
          return;
        }

        const usersData = await getUserData();
        const loggedInUserId = userStore.user.id ? userStore.user.id : -1;
        const filteredUsers = usersData.viewedBy.filter(
          (user) => user.id !== loggedInUserId,
        );
        setUsersViewing(filteredUsers.map((user) => user.name).join(', '));
        setOrderViewedBy(filteredUsers);

        // Lock feature is enabled.
        if (Boolean(orderStore.orderLockEnabled)) {
          if (usersData.lockedBy !== undefined && usersData.lockedBy !== null) {
            setOrderLockedBy(usersData.lockedBy);

            // Set to read-only only if the lockedBy id is not the same as the currently logged in user.
            setOrderStore(
              'isReadOnly',
              usersData.lockedBy.id !== loggedInUserId,
            );

            if (usersData.lockedBy.createdDateUTC) {
              startCountdown(new Date(usersData.lockedBy.createdDateUTC));
            }
          } else {
            setOrderStore('isReadOnly', false);
            setOrderLockedBy(null);
            setCountdown('');
            clearInterval(intervalCountDown);

            if (getIsFirstMount() === false) {
              await lockOrder();
            }
          }

          if (getIsFirstMount() === false) {
            setIsFirstMount(true);
          }
        } else {
          // Feature flag off scenario.
          setOrderLockedBy(null);
          setOrderStore('isReadOnly', false); // Enable save button if not locked by another user
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error fetching data:', error);
      }
    };

    const startCountdown = (createdDate: Date) => {
      const updateCountdown = () => {
        const date = DateTime.fromJSDate(createdDate).plus({ minutes: 10 });
        const remaining = date.diffNow().toObject();

        if ((remaining.milliseconds ?? 0) < 0) {
          setCountdown('00:00');
          return;
        }

        setCountdown(Duration.fromObject(remaining).toFormat('mm:ss'));
      };

      updateCountdown();
      if (intervalCountDown !== undefined) {
        clearInterval(intervalCountDown);
      }
      intervalCountDown = setInterval(updateCountdown, 1000);
    };

    const unlockOrder = async () => {
      try {
        await apiClient.delete(`order/lock/${props.orderId}`, {});
        await fetchUsersAndLock();
        setIsManualLock(false);
      } catch (error) {
        printLog('Error unlocking order:', error);
      }
    };

    const lockOrder = async () => {
      try {
        if (props.orderId && props.orderId !== '0') {
          await apiClient.post(`order/lock/${props.orderId}`, {});
          // setOrderLockedBy({
          //   id: userStore.user.id,
          //   name: userStore.user.name,
          //   createdDateUTC: new Date(),
          // });
        }
        await fetchUsersAndLock();
        setIsMessageOpen(false);
      } catch (error) {
        printLog('Error locking order:', error);
      }
    };

    const refreshLockOrder = async () => {
      try {
        if (props.orderId && props.orderId !== '0') {
          await apiClient.post(`order/lock/refresh/${props.orderId}`, {});
        }
        await fetchUsersAndLock();
        setIsMessageOpen(false);
      } catch (error) {
        printLog('Error locking order:', error);
      }
    };

    function doesWebsocketMessageApplyToUser(
      msgObj: LoadBoardWSMsgT,
      props: { orderId: string },
    ) {
      return (
        msgObj.id != null &&
        msgObj.id.toString() === props.orderId &&
        msgObj.userId != null &&
        msgObj.userId !== userStore.user.id
      );
    }

    let interval: string | number | NodeJS.Timeout | undefined;
    let intervalCountDown: string | number | NodeJS.Timeout | undefined;
    let socketv = globalState.socketv;

    createEffect(() => {
      setLoggedInUserId(userStore.user.id ? userStore.user.id : -1);
    });

    onMount(() => {
      void (async () => {
        await userStore.userPromise;

        if (Boolean(userStore.user.id)) {
          await Promise.all([
            GetOrderLockFeatureFlag(),
            GetForceUnlockOrderLockFeatureFlag(),
          ]);
          await fetchUsersAndLock();

          if (orderStore.lockedBy?.id === loggedInUserId()) {
            setIsFirstMount(true);
          }

          if (interval !== undefined) {
            clearInterval(interval);
          }

          interval = setInterval(() => {
            void fetchUsersAndLock();
          }, 30000); // Fetch every 30 seconds
          socketv = globalState.socketv;

          if (socketv !== undefined) {
            if (socketHandlerInitialized() === false) {
              globalState.socketv!.on(
                'RecieveWebSocketMessage',
                async (message: string) => {
                  const msgObj = JSON.parse(message) as LoadBoardWSMsgT;

                  if (getUserDataViewLocksComposite() === null) {
                    // race with first result.
                    return;
                  }

                  if (!doesWebsocketMessageApplyToUser(msgObj, props)) {
                    return;
                  }

                  const key = `${msgObj.userId}.${msgObj.id}`;

                  if (
                    msgObj.eventName === LoadBoardEventEnum.View ||
                    msgObj.eventName === LoadBoardEventEnum.Leave
                  ) {
                    if (
                      msgObj.eventName === LoadBoardEventEnum.View &&
                      (getUserDataViewLocksComposite()?.viewedBy.find(
                        (user) => user.id === msgObj.userId,
                      ) !== undefined ||
                        key in userViewingMessages)
                    ) {
                      // We already have this user. Nothing to do.
                      return;
                    } else if (
                      msgObj.eventName === LoadBoardEventEnum.Leave &&
                      (getUserDataViewLocksComposite()?.viewedBy.find(
                        (user) => user.id === msgObj.userId,
                      ) === undefined ||
                        !(key in userViewingMessages))
                    ) {
                      // We don't have this user. Nothing to do.
                      return;
                    }

                    if (msgObj.eventName === LoadBoardEventEnum.View) {
                      userViewingMessages[key] = true;
                    } else {
                      delete userViewingMessages[key];
                    }

                    printLog(
                      `Received ${
                        msgObj.eventName === LoadBoardEventEnum.View
                          ? 'View'
                          : 'Leave'
                      } WebSocket message:`,
                      msgObj,
                    );
                    await fetchUsersAndLock();
                    return;
                  }

                  // We only need to handle lock events if feature flag enabled.
                  if (
                    orderStore.orderLockEnabled != undefined &&
                    orderStore.orderLockEnabled
                  ) {
                    if (msgObj.eventName === LoadBoardEventEnum.Lock) {
                      if (
                        getUserDataViewLocksComposite()?.lockedBy ===
                          undefined &&
                        getUserDataViewLocksComposite()?.lockedBy?.id !==
                          msgObj.userId
                      ) {
                        printLog('Received Lock WebSocket message:', msgObj);
                        await fetchUsersAndLock();
                        return;
                      }
                    }

                    if (msgObj.eventName === LoadBoardEventEnum.Unlock) {
                      if (
                        getUserDataViewLocksComposite()?.lockedBy !== undefined
                      ) {
                        setCountdown('');
                        clearInterval(intervalCountDown);
                        printLog('Received Unlock WebSocket message:', msgObj);
                        await fetchUsersAndLock();
                      }
                    }
                  }
                },
              );
            }
            setSocketHandlerInitialized(true);
          }
        }
      })();
    });
    const sendUnlockBeacon = () => {
      const url = `${ConfigManager.apiUrl}/api/order/lock/navigatorbeacon/${props.orderId}`;
      navigator.sendBeacon(url, JSON.stringify({}));
    };

    let previousUrl = window.location.pathname;

    const intervalId = setInterval(() => {
      if (window.location.pathname !== previousUrl) {
        previousUrl = window.location.pathname;

        // Trigger sendBeacon when route changes away from shell app's route
        sendUnlockBeacon();
      }
    }, 1000);

    onCleanup(async () => {
      await apiClient.delete(`order/viewing/leave/${props.orderId}`, {});
      clearInterval(interval);
      clearInterval(intervalCountDown);
      clearInterval(intervalId);
      socketv?.off('RecieveWebSocketMessage');
    }); // Clear interval and WebSocket listener on component unmount

    window.addEventListener('pagehide', sendUnlockBeacon);
    window.addEventListener('beforeunload', sendUnlockBeacon);

    return (
      <Show
        when={!orderStore.loading}
        fallback={<Skeleton variant="text" width="100%" height={100} />}
      >
        <Show
          when={
            (getUsersViewing().length || isOrderLockEnabled()) &&
            props.orderId &&
            props.orderId !== '0'
          }
        >
          <Container>
            <Stack direction="column">
              <Stack direction="row" gap={1.5} alignItems="center">
                <Box>
                  <Visibility htmlColor="#01579B" />
                </Box>
                <Box flex={1}>
                  <Show
                    when={
                      orderStore.orderLockEnabled != undefined &&
                      orderStore.orderLockEnabled &&
                      !orderStore.lockedBy &&
                      getUsersViewing().length === 0
                    }
                  >
                    Click the button to lock the load.
                  </Show>
                  <Show when={getUsersViewing().length !== 0}>
                    <Box>
                      <Typography
                        sxProps={{ fontWeight: '500' }}
                        variant="body2"
                      >
                        There are other users currently in this load.
                      </Typography>
                      <Box fontSize="12px">{getUsersViewing()}</Box>
                    </Box>
                  </Show>
                  <Show when={orderStore.lockedBy?.id === loggedInUserId()}>
                    <Box mt="6px">
                      <Typography
                        sxProps={{ fontWeight: '500' }}
                        variant="body2"
                      >
                        You locked this load -{' '}
                        {DateTime.fromISO(
                          orderStore.lockedBy
                            ?.createdDateUTC as unknown as string,
                        ).toFormat('LLL dd, yyyy t')}
                      </Typography>
                    </Box>
                  </Show>
                  <Show
                    when={
                      Boolean(orderStore.lockedBy) &&
                      orderStore.lockedBy?.id !== loggedInUserId()
                    }
                  >
                    <Box mt="6px">
                      <Typography
                        sxProps={{ fontWeight: '500' }}
                        variant="body2"
                      >
                        This load was locked by {orderStore.lockedBy?.name} -{' '}
                        {DateTime.fromISO(
                          orderStore.lockedBy
                            ?.createdDateUTC as unknown as string,
                        ).toFormat('LLL dd, yyyy t')}
                      </Typography>
                    </Box>
                  </Show>
                </Box>

                <Box flex={1}>
                  <Stack direction="row" gap={1} fontWeight="bold">
                    <Box
                      fontSize="14px"
                      pt={
                        orderStore.lockedBy?.id !== loggedInUserId()
                          ? undefined
                          : '7px'
                      }
                    >
                      {countdown()}
                    </Box>
                    <Show
                      when={
                        Boolean(orderStore.lockedBy) &&
                        orderStore.lockedBy?.id === loggedInUserId()
                      }
                    >
                      <Box fontSize="14px">
                        <Button
                          startIcon={<Refresh />}
                          variant="text"
                          onClick={() => refreshLockOrder()}
                        >
                          Reset Timer
                        </Button>
                      </Box>
                    </Show>
                  </Stack>
                  <Box fontSize="12px">
                    <Box
                      maxWidth="450px"
                      lineHeight="14px"
                      fontWeight="bold"
                      color="#ED6C02"
                    >
                      <Show
                        when={
                          !Boolean(orderStore.lockedBy) && isOrderLockEnabled()
                        }
                      >
                        Load Available
                      </Show>
                      <Show
                        when={
                          Boolean(orderStore.lockedBy) &&
                          orderStore.lockedBy?.id !== loggedInUserId()
                        }
                      >
                        The load will become available when the 10-minute timer
                        ends.
                      </Show>
                      <Show
                        when={
                          Boolean(orderStore.lockedBy) &&
                          orderStore.lockedBy?.id === loggedInUserId()
                        }
                      >
                        {isManualLock()
                          ? 'This load will automatically unlock when the 10-minute timer ends, or if you choose to unlock the load by clicking on the “Unlock Load” button.'
                          : // eslint-disable-next-line quotes
                            "The load will be available to other Agents when the 10-minute timer ends, or if you click 'Unlock Load'."}
                      </Show>
                    </Box>
                  </Box>
                </Box>

                <Box height="100%">
                  <Show
                    when={isOrderLockEnabled() || Boolean(orderStore.lockedBy)}
                  >
                    <Box>
                      <Button
                        variant={
                          orderStore.lockedBy &&
                          orderStore.lockedBy?.id !== loggedInUserId() &&
                          !Boolean(orderStore.forceUnlockOrderEnabled)
                            ? 'text'
                            : 'contained'
                        }
                        startIcon={
                          !orderStore.lockedBy ||
                          orderStore.lockedBy?.id !== loggedInUserId() ? (
                            <Lock />
                          ) : (
                            <Key />
                          )
                        }
                        size="small"
                        color="primary"
                        class={`!text-armstrong-sm ${
                          orderStore.lockedBy &&
                          orderStore.lockedBy?.id !== loggedInUserId() &&
                          !Boolean(orderStore.forceUnlockOrderEnabled)
                            ? '!bg-white'
                            : '!bg-[#1B4960]'
                        }`}
                        disabled={
                          Boolean(orderStore.lockedBy) &&
                          orderStore.lockedBy?.id !== loggedInUserId() &&
                          !Boolean(orderStore.forceUnlockOrderEnabled)
                        }
                        onClick={async () => {
                          if (!orderStore.lockedBy) {
                            await lockOrder();
                            setIsManualLock(true);
                          } else {
                            await unlockOrder();
                          }
                        }}
                      >
                        <Show when={!orderStore.lockedBy}>Lock Load</Show>
                        <Show
                          when={
                            orderStore.lockedBy &&
                            (orderStore.lockedBy?.id === loggedInUserId() ||
                              orderStore.forceUnlockOrderEnabled)
                          }
                        >
                          Unlock Load
                        </Show>
                        <Show
                          when={
                            orderStore.lockedBy &&
                            orderStore.lockedBy?.id !== loggedInUserId() &&
                            !Boolean(orderStore.forceUnlockOrderEnabled)
                          }
                        >
                          Load Locked
                        </Show>
                      </Button>
                    </Box>
                  </Show>
                </Box>
              </Stack>
              <Show
                when={
                  isOrderLockEnabled() &&
                  ((Boolean(orderStore.lockedBy) &&
                    orderStore.lockedBy?.id !== loggedInUserId()) ||
                    !Boolean(orderStore.lockedBy))
                }
              >
                <Stack direction="row" gap={1.5} mt="12px">
                  <Box>
                    <Warning htmlColor="#F59D25" />
                  </Box>
                  <Box flex={1}>
                    <Typography
                      sxProps={{ cursor: 'pointer', fontWeight: '500' }}
                      variant="body2"
                      onClick={() => {
                        setIsMessageOpen((prev) => !prev);
                      }}
                    >
                      <Box pt="5px">
                        <Show
                          when={
                            Boolean(orderStore.lockedBy) &&
                            orderStore.lockedBy?.id !== loggedInUserId()
                          }
                        >
                          While the load is locked, access is limited to
                          following fields:
                        </Show>
                        <Show when={!Boolean(orderStore.lockedBy)}>
                          Until you’ve locked this load, access is limited to
                          the following fields:
                        </Show>{' '}
                        {isMessageOpen() ? (
                          <KeyboardArrowUp htmlColor="#026EA1" />
                        ) : (
                          <KeyboardArrowDown htmlColor="#026EA1" />
                        )}
                      </Box>
                      <Show when={isMessageOpen()}>
                        copy order, select template, templates, view edit
                        history, order sheet, customer confirmation, invoice,
                        open customer profile, internal notes, upload documents,
                        claim, truck search, open carrier profile, favorite
                        carrier, rate carrier, post load, manage posting,
                        tracking and add to playlist.
                      </Show>
                    </Typography>
                  </Box>
                  <Box width="124px"></Box>
                </Stack>
              </Show>
            </Stack>
          </Container>
        </Show>
      </Show>
    );
  });
};
