import { apiClient } from '@api/apiClient';
import { isEmpty } from 'lodash';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import {
  AddressBook,
  AddressBookModel,
  FilteredAddressBook,
  NewAddressResponse,
} from './types';
import { setAddressBookStore } from './store';

export const getFilteredAddressBookData = async (
  payload: Partial<FilteredAddressBook>,
) => {
  try {
    const response: {
      addresses: AddressBookModel[];
      totalCount: number;
    } = await apiClient.get('CustomerAddress/getcustomeraddress', {
      params: payload,
    });
    return response;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch Address Book Data');
  }
};

export const postAddress = async (payload: AddressBook) => {
  try {
    const { cb, ...restPayload } = payload;
    const response: NewAddressResponse = await apiClient.post(
      'CustomerAddress',
      restPayload,
    );
    if (response.isSuccess) {
      cb && cb('Address Successfully Saved!');
      return response;
    }
  } catch (error) {
    handleError(error, 'Failed to save address');
  }
};

export const editAddress = async (payload: AddressBook) => {
  try {
    const { cb, ...restPayload } = payload;
    const response: AddressBookModel = await apiClient.put(
      'CustomerAddress',
      restPayload,
    );
    if (!isEmpty(response)) {
      cb && cb('Address Successfully Updated!');
      return response;
    }
  } catch (error) {
    handleError(error, 'Failed to update address');
  }
};

const handleError = (error: unknown, toastMessage: string) => {
  if (Array.isArray(error)) {
    const errorMessages = error.map((v) => {
      if (typeof v === 'string') {
        return v;
      } else if (typeof v === 'object' && v !== null && 'errorMessage' in v) {
        return (v as { errorMessage: string }).errorMessage;
      }
      return 'Unspecified/unknown error';
    });
    setAddressBookStore('addOrEditErrorMessage', errorMessages.join(', '));
  } else {
    throw new Error(toastMessage);
  }
  handleToast(ToastType.Error, toastMessage);
};

export const deleteAddressBook = async (addressId: string) => {
  try {
    const res: { isSuccess: boolean } = await apiClient.delete(
      `CustomerAddress/${addressId}`,
    );
    if (res.isSuccess) {
      handleToast(ToastType.Success, 'Address Successfully Deleted!');
      return res.isSuccess;
    }
  } catch (error: unknown) {
    throw new Error(`Failed to delete address: ${error}`);
  }
};

export const fetchAddressBookById = async (addressId: string) => {
  try {
    const res: AddressBookModel = await apiClient.get(
      `CustomerAddress/getaddressbyid/${addressId}`,
    );
    return res;
  } catch (error: unknown) {
    throw new Error(`Failed to delete address: ${error}`);
  }
};
