import { Coordinate } from '@components/Map/Map';
import { DocumentModel } from '@store/documents';
import { createSelectMenuItemsFroObjectOfArrays } from '@utils/utils';
import { createStore } from 'solid-js/store';

import { DEFAULT_ORDER_STATE, defaultCustomerDetails } from './defaultVals';
import {
  Carrier,
  CarrierState,
  CommentViewModel,
  CustomerDetails,
  CustomerState,
  EdiLogState,
  FeatureFlagCustomer,
  FeatureFlagCustomerState,
  ILoadTender,
  IManagePostLoad,
  IOrderViewModel,
  IPostLoad,
  LastUsedTopStopModel,
  LoadTenderState,
  OrderDocumentState,
  OrderState,
  Playlist,
  PostLoadState,
  TabType,
  UserData,
  availableServices,
  ediLog,
} from './types';

const DEFAULT_TAB_STATE = {
  index: 0,
  type: TabType.load,
};

const initManagePostLoad: IManagePostLoad = {
  id: -1,
  loadId: -1,
  boardsPostedTo: [],
  origin: '',
  originCity: '',
  originState: '',
  originCountry: '',
  destination: '',
  destinationCity: '',
  destinationState: '',
  destinationCountry: '',
  pickUp: '',
  dropOff: '',
  price: null,
  equipment: '',
  comment: '',
  drops: 0,
  length: 0,
  weight: 0,
  servicesToPostTo: [],
  fullTruckload: false,
  mileage: 0,
  internalNote: '',
};

const [orderStore, setOrderStore] = createStore<OrderState>({
  order: DEFAULT_ORDER_STATE,
  orderFormError: null,
  orderFormErrorPerSection: {
    customerInformation: 0,
    loads: 0,
    instructionsAndCosts: 0,
  },
  mappedErrorSection: null,
  loading: false,
  isSaving: false,
  isReadOnly: false,
  isError: false,
  activeTab: DEFAULT_TAB_STATE,
  lastStops: [],
  topStops: [],
  mode: '',
  mapCords: [{ lat: 0, lng: 0 }],
  availableServices: [{ id: 0, name: '', quickPostInclude: false }],
  managePostLoad: initManagePostLoad,
  nearbyCarriers: [],
  isCreate: false,
  firstRender: true, // TODO - Revist This As it is Added just to Check the First Render So ShipmentsModal can be opened Once
  isStopsEdited: false,
  orderTemplates: [],
  carrierContactLockdownEnabled: undefined,
  isTemplateMode: false,
  matched: false,
  //Falvey insurance error as vendor will always be one in this case adding a string
  vendorFalveyInsuranceError: '',
  copiedOrderIdUrl: '',
  copiedOrderId: '',
  orderIdUrl: undefined,
  quoteIdUrl: undefined, //this is the dbo.LTLQuote.Id
  quoteId: undefined, //this is the ltl.Quote.Id
  orderLockEnabled: undefined,
  forceUnlockOrderEnabled: undefined,
  isSmallScreen: false,
  viewedBy: [],
});

// @ts-expect-error stuff
window.store = { orderStore, setOrderStore };

const [customerStore, setCustomerStore] = createStore<CustomerState>({
  customer: defaultCustomerDetails,
  loading: false,
});

const [postLoadStore, setPostLoadStore] = createStore<PostLoadState>({
  postLoad: {} as IPostLoad,
  isError: false,
  loading: true,
});
const [orderDocumentStore, setOrderDocumentStore] =
  createStore<OrderDocumentState>({
    documents: [] as DocumentModel[],
    loading: true,
  });

const [ediLogsStore, setEdiLogsStore] = createStore<EdiLogState>({
  ediLogs: [] as ediLog[],
  loading: false,
  isError: false,
});

const [loadTenderStore, setLoadTenderStore] = createStore<LoadTenderState>({
  loadTender: {} as ILoadTender,
  loading: true,
  isError: false,
});

const [carrierStore, setCarrierStore] = createStore<CarrierState>({
  carrier: [],
  featureFlags: [],
  playlists: [],
  loading: false,
  isError: false,
});

const [ediSettingsValues, setEdiSettingsValues] = createStore({
  ediSettings: {},
  loading: false,
  isError: false,
});

const [featureFlagGetCustomerFeatures, setFeatureFlagGetCustomerFeatures] =
  createStore<FeatureFlagCustomerState>({
    featureFlagCustomer: {
      CustomerCommentVisibility: false,
      suggestedClass: false,
      EDIDropoffDriverInAuto214: false,
      EDIDropoffDriverOutAuto214: false,
      EDIDropoffAppointmentAuto214: false,
      EDIPickupDriverInAuto214: false,
      EDIPickupDriverOutAuto214: false,
      EDIPickupAppointmentAuto214: false,
    },
    loading: false,
    isError: false,
  });

export function setFeatureFlagGetCustomerFeaturesData(
  data: FeatureFlagCustomer,
) {
  setFeatureFlagGetCustomerFeatures('featureFlagCustomer', data);
}

export function setCopiedOrderId(copyOrderId: string) {
  setOrderStore('copiedOrderId', copyOrderId);
}

export function setOrderViewedBy(users: UserData[]) {
  setOrderStore('viewedBy', users);
}

export function setOrderLockedBy(user: UserData | undefined | null) {
  setOrderStore('lockedBy', user);
}

export function setFeatureFlagGetCustomerFeaturesLoading(loading: boolean) {
  setFeatureFlagGetCustomerFeatures('loading', loading);
}

export function setFeatureFlagGetCustomerFeaturesError(error: boolean) {
  setFeatureFlagGetCustomerFeatures('isError', error);
}

export function setEdiSettingsValuesData(data: Record<string, string[]>) {
  setEdiSettingsValues(
    'ediSettings',
    createSelectMenuItemsFroObjectOfArrays(data),
  );
}

export function setEdiSettingsValuesLoading(loading: boolean) {
  setEdiSettingsValues('loading', loading);
}

export function setEdiSettingsValuesError(error: boolean) {
  setEdiSettingsValues('isError', error);
}

// Function to set order data
export function setOrderData(data: IOrderViewModel) {
  setOrderStore('order', data);
}

export function setFlagComments(
  loadIdx: number,
  flagIdx: number,
  comments: CommentViewModel[],
) {
  setOrderStore(
    'order',
    'loads',
    loadIdx,
    'flags',
    flagIdx,
    'comments',
    comments,
  );
}

// Function to set loading state
export function setOrderLoading(loading: boolean) {
  setOrderStore('loading', loading);
}
export function setMode(mode: string) {
  setOrderStore('mode', mode);
}

export function setOrderFirstRender(firstRender: boolean) {
  setOrderStore('firstRender', firstRender);
}

export function setAvailableSevices(data: availableServices[]) {
  setOrderStore('availableServices', data);
}
export function setCustomerData(data: CustomerDetails) {
  setCustomerStore(() => ({
    customer: data,
    isLoading: false,
  }));
}

export function setManagePostLoadData(data: IManagePostLoad) {
  setOrderStore('managePostLoad', data);
}
export function setPostLoadData(data: IPostLoad) {
  setPostLoadStore(() => ({
    postLoad: data,
    isLoading: false,
  }));
}
export function setEdiLogsData(data: ediLog[]) {
  setEdiLogsStore(() => ({
    ediLogs: data,
    isLoading: false,
  }));
}

export function setCustomerLoading(loading: boolean) {
  setCustomerStore('loading', loading);
}

export function setMapCords(latlngArray: Coordinate[]) {
  setOrderStore('mapCords', latlngArray);
}

export function setStops(
  topStops: LastUsedTopStopModel[],
  pastStops: LastUsedTopStopModel[],
) {
  setOrderStore(() => ({
    topStops: topStops,
    lastStops: pastStops,
  }));
}

export function setDocuments(documents: DocumentModel[]) {
  setOrderDocumentStore(() => ({
    documents: documents,
    loading: false,
  }));
}

export function setDocumentsLoading(loading: boolean) {
  setOrderDocumentStore('loading', loading);
}

export function setCarrierLoading(loading: boolean) {
  setCarrierStore('loading', loading);
}

export function setPlaylists(data: Playlist[]) {
  setCarrierStore('playlists', data);
}
export function setCarrierData(carrier: Carrier) {
  setCarrierStore('carrier', carrier);
}

export {
  carrierStore,
  customerStore,
  ediLogsStore,
  ediSettingsValues,
  featureFlagGetCustomerFeatures,
  loadTenderStore,
  orderDocumentStore,
  orderStore,
  postLoadStore,
  setCarrierStore,
  setCustomerStore,
  setEdiLogsStore,
  setEdiSettingsValues,
  setFeatureFlagGetCustomerFeatures,
  setLoadTenderStore,
  setOrderDocumentStore,
  setOrderStore,
  setPostLoadStore,
};
