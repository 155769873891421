import { produce } from 'solid-js/store';

import { deleteAddressBook, fetchAddressBookById } from './services';
import { setAddressBookStore } from './store';
import { AddressBookState } from './types';

export const deleteAddress = async (addressId: string) => {
  try {
    return await deleteAddressBook(addressId);
  } catch (error) {
    setAddressBookStore(
      produce((draft: AddressBookState) => {
        draft.isError = true;
        draft.items = [];
        draft.isLoading = false;
      }),
    );
  }
};

export const getAddressBookById = async (id: string) => {
  try {
    const res = await fetchAddressBookById(id);
    setAddressBookStore('selectedAddress', res);
  } catch (error) {
    throw new Error(`Failed to fetch address: ${error}`);
  }
};
