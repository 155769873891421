import { createStore, produce } from 'solid-js/store';
import { IsFeatureFlagEnabledByName } from '@store/Global/service';

import { FeatureFlagState } from './types';

const [featureFlagStore, setFeatureFlagStore] = createStore<FeatureFlagState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  flags: {},
  overlay: false,
});

export const setFlagValue = (
  flagName: string,
  flagProperty: string,
  enabled: boolean,
) => {
  setFeatureFlagStore(
    produce((draft: FeatureFlagState) => {
      if (flagProperty in draft.flags) {
        draft.flags[flagProperty].enabled = enabled;
      } else {
        draft.flags[flagProperty] = {
          enabled: enabled,
          name: flagName,
        };
      }
      draft.isLoading = false;
    }),
  );
};

export const setFailedFeatureFlagValueGet = (flagName: string) => {
  setFeatureFlagStore(
    produce((draft: FeatureFlagState) => {
      draft.isError = true;
      draft.errorMessage = `Failed getting read for feature flag ${flagName}`;
      draft.isLoading = false;
    }),
  );
};

export const getFeatureFlag = async (
  flagName: string,
  flagProperty: string,
) => {
  try {
    setFeatureFlagStore('isLoading', true);
    const resp = (await IsFeatureFlagEnabledByName(flagName)) ?? false;
    setFlagValue(flagName, flagProperty, resp);
  } catch (e) {
    setFailedFeatureFlagValueGet(flagName);
  } finally {
    setFeatureFlagStore('isLoading', false);
  }
};

export const fetchReadFromNewLtlTables = async () => {
  const flagName = 'ltl_VNEXT-2856_TableRefactor_ReadFromNew';
  const flagProperty = 'isReadFromNewLtlTables';
  await getFeatureFlag(flagName, flagProperty);
};

export const fetchWriteToOldLtlTables = async () => {
  const flagName = 'ltl_VNEXT-2856_TableRefactor_WriteToOld';
  const flagProperty = 'isWriteToOldLtlTables';
  await getFeatureFlag(flagName, flagProperty);
};

export const GetStopMileageEnabled = async () => {
  const flagName = 'StopMileage';
  const flagProperty = 'isStopMileage';
  await getFeatureFlag(flagName, flagProperty);
};

export { featureFlagStore };
