import { Show } from 'solid-js';
import { orderStore } from '@store/orders';

import LTLQuote from '../ltlQuote/LTLQuote';
import { canRateLtl } from './helpers';
import DeprecatedFalveyLTLQuote from '../ltlQuote/DeprecatedFalveyLTLQuote'; //CLEAN UP IN TICKET VNEXT-2998

type Props = {
  useNewTables: boolean;
};

export const LTLInfo = (props: Props) => {
  const load = () => orderStore.order.loads[orderStore.activeTab.index];
  const quoteId = () => orderStore.quoteId ?? '';

  return (
    <Show
      when={load().mode === 'LTL' && Boolean(load().quoteId) && load().ltlQuote}
    >
      {props.useNewTables ? (
        <LTLQuote
          canRateLtl={canRateLtl(load())}
          status={load().status}
          loadId={load().id}
          quoteId={quoteId()}
        />
      ) : (
        <DeprecatedFalveyLTLQuote //CLEAN UP IN TICKET VNEXT-2998
          canRateLtl={canRateLtl(load())}
          ltlQuote={load().ltlQuote}
          status={load().status}
        />
      )}
    </Show>
  );
};
