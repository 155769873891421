import {
  apiClient,
  ApiResponse,
  mapClient,
  pcMilerClient,
  v3Client,
  v3ODataClient,
} from '@api/apiClient';
import { ToastType } from '@components/Toast';
import { V4ApiResponse } from '@store/customers/customerDetails';
import { DocumentModel } from '@store/documents';
import {
  setCustomerInfo,
  setLoadItems,
  setLTLQuote,
  setOriginOrDestinationInfo,
  setPickupDate,
} from '@store/ltl/store';
import {
  loadCustomerInfo,
  removeVendorFromOrder,
  updateLoadPropertyAtIndex,
} from '@store/orders/actions';
import { logError } from '@utils/errorLogging';
import { createError } from '@utils/errorUtils';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { handleToast, printLog } from '@utils/utils';
import { ICityState, IZip } from '@views/order/components';
import { TrackingCommentSchema } from '@views/order/components/customerTracking';
import { AxiosError, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { orderStore, setOrderStore } from './store';
import {
  AddFlagCommentPayload,
  AppointmentReminderModel,
  availableServices,
  Carrier,
  CarrierFeatures,
  CarrierInsuranceDetail,
  CheckCarrierConditionalApiResponse,
  Comments,
  CommitQuoteResult,
  Contact,
  CustomerAddress,
  CustomerDetails,
  ediLog,
  EDIStatusCodesApiResponse,
  FalveyInsuranceQuote,
  FeatureFlagCustomer,
  FetchPricingForPostingPayload,
  FetchPricingForPostingResponse,
  FlagViewModel,
  GeocodeModel,
  ICreditCardPayment,
  ICustomerContact,
  ILatLon,
  ILoadComments,
  ILoadEditViewModel,
  ILoadTender,
  IManagePostLoad,
  IOrderViewModel,
  IPostLoad,
  LastUsedTopStopModel,
  LoadCommentResponse,
  LoadEdiStatusUpdatePayload,
  LoadSearch,
  loadStatus,
  LtlQuoteAccessorialViewModel,
  LtlQuoteLineItemViewModel,
  LtlQuoteLoadItemViewModel,
  LtlQuoteParametersViewModel,
  LtlTerminalInfoViewModel,
  LTLTrackingData,
  NearbyCarrierRequestViewModel,
  NearbyCarriersReturn,
  NewLtlQuoteViewModel,
  ODataApiResponse,
  OrderTemplate,
  PcMilerResponse,
  PickUpDropOffItem,
  Playlist,
  RatePayloadType,
  RevenueSearchData,
  ShareCapacityResult,
  ShareUnShareCapacityPayload,
  SubmitShipmentResult,
  TrackingData,
  TrackingResponse,
  UserInfoModel,
} from './types';
import { CustomerContactViewModel } from './v3Types';

export const testCall404 = async () => {
  try {
    await apiClient.post('/this/will/fail');
    return true;
  } catch (error: unknown) {
    logError('testCall404', error as Error);
    handleToast(ToastType.Error, 'Failed to Change Rate Confirmation Status');
  }
};

export async function setRateConRecieved(loadId: number, recieved: boolean) {
  try {
    await apiClient.post(
      `/load/RateConRecieved/${loadId}?recieved=${recieved}`,
    );
    handleToast(ToastType.Success, 'Rate Confirmation Status Changed');
    return true;
  } catch (error: unknown) {
    logError('setRateConReceived', error as Error);
    handleToast(ToastType.Error, 'Failed to Change Rate Confirmation Status');
  }
}

export async function deletePosting(
  postingId: number | null,
): Promise<boolean | undefined> {
  try {
    const response: boolean = await apiClient.delete(`/posting/${postingId}`);
    if (Boolean(response)) {
      handleToast(ToastType.Success, 'Posting deleted successfully');
    }
    return response;
  } catch (error: unknown) {
    logError('deletePosting', error as Error);

    if (error instanceof AxiosError) {
      handleToast(
        ToastType.Error,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `Error with Posting Load: ${error.response?.data?.message}`,
      );
    }
  }
}

export async function postPosting(loadId: number): Promise<number | undefined> {
  try {
    const response: {
      data: { id: number };
      errors: [];
      partialSuccess: boolean;
    } = await apiClient.post('/posting/quickpost', {
      loadId: loadId,
    });
    if (response.partialSuccess) {
      const errors = response.errors.map((error: string) => {
        if (error.includes('TruckStop') && error.includes('password')) {
          return 'TruckStop Password Is Invalid';
        }
        return error;
      });
      handleToast(
        ToastType.Caution,
        `Posting Load Partial Success: ${errors.join(', ')}`,
      );
    } else {
      handleToast(ToastType.Success, 'Posting Load Success');
    }
    return response.data.id;
  } catch (error: unknown) {
    logError('postPosting', error as Error);

    if (error instanceof AxiosError) {
      handleToast(
        ToastType.Error,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `Error with Posting Load: ${error.response?.data?.message}`,
      );
    }
  }
}

export async function postLoadStatus(
  loadStatus: loadStatus,
): Promise<boolean | undefined> {
  try {
    const response: ApiResponse<boolean> = await apiClient.post<
      loadStatus,
      ApiResponse<boolean>
    >('/load/changestatus', loadStatus);
    if (response.isSuccess) {
      handleToast(ToastType.Success, 'Load Status Updated');
    } else {
      handleToast(ToastType.Error, 'Failed to update status');
    }
    return response.isSuccess;
  } catch (error: unknown) {
    logError('postLoadStatus', error as Error);

    if (error instanceof Error) {
      throw new Error(`Failed to update status: ${error.message}`);
    }
  }
}

export async function fetchOrderLoadboard(orderId: string) {
  try {
    const response: { value: IOrderViewModel } = await apiClient.get(
      `/order/${orderId}`,
    );
    const loads = response.value.loads.map((load) => {
      return {
        ...load,
        stops:
          load.stops?.map((s) => {
            return {
              ...s,
              // driverInTime:
              //   s.driverInTime !== undefined && s.driverInTime !== null
              //     ? DateTime.fromISO(s.driverInTime, { zone: 'utc' })
              //         .toLocal()
              //         .toISO()
              //     : undefined,
              // driverOutTime:
              //   s.driverOutTime !== undefined && s.driverOutTime !== null
              //     ? DateTime.fromISO(s.driverOutTime, { zone: 'utc' })
              //         .toLocal()
              //         .toISO()
              //     : undefined,
            };
          }) ?? [],
      };
    });
    const orderData = {
      ...response.value,
      loads,
    };
    SetLTLQuoteRelatedData(orderData);
    if (orderData.customerId !== null) {
      await loadCustomerInfo(orderData.customerId);
    }

    return orderData;
  } catch (error: unknown) {
    logError('fetchOrderLoadboard', error as Error);
    throw createError(error, 'Failed to fetch order for loadboard');
  }
}

function SetLTLQuoteRelatedData(orderData: IOrderViewModel) {
  if (orderData.loads[0].quoteId !== null) {
    setLTLQuote({
      ltlQuote: orderData.loads[0].ltlQuote,
    });
    if (
      orderData.customerId !== 0 &&
      orderData.customerId !== null &&
      orderData.customerName !== undefined
    ) {
      setCustomerInfo(orderData.customerId, orderData.customerName);
    }

    if (orderData.loads[0].items !== undefined) {
      setLoadItems(
        orderData.loads[0].items.map((item) => {
          return {
            lengthInch: item.lengthInch,
            widthInch: item.widthInch,
            heightInch: item.heightInch,
            weight: item.weight,
            quantity: item.quantity,
            type: item.item,
            uom: 'lbs',
            item: item.description,
            class: Number(item.class),
            suggestedClass: item.suggestedClass,
            nmfc: item.nmfc ?? '',
            description: item.description,
            hazmat: item.hazmat,
            loadItemHazmatDetail: item.loadItemHazmatDetail,
            pieces: Number(item.pieces),
          };
        }),
      );
    }
    const pickupStop = orderData.loads[0]?.stops?.find(
      (x) => x.pickUp === true,
    );
    const dropoffStop = orderData.loads[0]?.stops?.find(
      (x) => x.pickUp === false,
    );
    if (pickupStop) {
      setOriginOrDestinationInfo({
        type: 'Origin',
        city: pickupStop.city,
        state: pickupStop.state,
        zip: pickupStop.zip,
        country: pickupStop.countryCode,
      });
      if (
        pickupStop.stopDateTime !== undefined &&
        pickupStop.stopDateTime !== null
      ) {
        setPickupDate(pickupStop.stopDateTime);
      }
    }
    if (dropoffStop) {
      setOriginOrDestinationInfo({
        type: 'Destination',
        city: dropoffStop.city,
        state: dropoffStop.state,
        zip: dropoffStop.zip,
        country: dropoffStop.countryCode,
      });
    }
  }
}

export async function fetchOrder(orderId: string): Promise<IOrderViewModel> {
  try {
    const response = await apiClient.get<IOrderViewModel>(`/order/${orderId}`);
    let orderData = response.value as IOrderViewModel;
    //Note: setting the bid type to Spot Market if it is null
    orderData = {
      ...orderData,
      bidType: Boolean(orderData.bidType) ? orderData.bidType : 'Spot Market',
    };
    if (orderData.customerId !== null) {
      await loadCustomerInfo(orderData.customerId);
    }

    SetLTLQuoteRelatedData(orderData);

    return orderData;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.log(error, 'error');
    if (error instanceof Error) {
      throw new Error(`Failed to fetch offices: ${error.message}`);
    }
    throw new Error(`Failed to fetch offices: ${error}`);
  }
}

export async function fetchCopyOrder(
  copyOrderId: string,
): Promise<IOrderViewModel> {
  try {
    const response = await apiClient.get<IOrderViewModel>(
      `/order/GetCopyOrder/${copyOrderId}`,
    );
    let orderData = response.value as IOrderViewModel;
    //Note: setting the bid type to Spot Market if it is null
    orderData = {
      ...orderData,
      loads: orderData.loads.map((load) => {
        return {
          ...load,
          stops: load.stops?.map((stop) => {
            return {
              ...stop,
              stopDateTime: stop.dropOff ? undefined : stop.stopDateTime,
            };
          }),
        };
      }),
      bidType: Boolean(orderData.bidType) ? orderData.bidType : 'Spot Market',
    };
    if (orderData.customerId !== null) {
      await loadCustomerInfo(orderData.customerId);
    }

    SetLTLQuoteRelatedData(orderData);

    return orderData;
  } catch (error: unknown) {
    logError('fetchOrder', error as Error);

    if (error instanceof Error) {
      throw new Error(`Failed to fetch offices: ${error.message}`);
    }
    throw new Error(`Failed to fetch offices: ${error}`);
  }
}

export const getBookedStatus = async (loadId: number) => {
  try {
    const response: { value: number[] } = await apiClient.get(
      `/capacity/booked/bid/load/${loadId}`,
    );
    if (Boolean(response.value[0])) {
      setOrderStore(
        'order',
        'loads',
        (load) => load.id === loadId,
        'carrierMatchId',
        response.value[0],
      );
      handleToast(ToastType.Success, 'This load is Carrier Matched');
    }
  } catch (error) {
    logError('getBookedStatus', error as Error);
  }
};

export async function fetchCustomerInfo(
  customerId: number,
): Promise<CustomerDetails> {
  try {
    const response = await apiClient.get<CustomerDetails>(
      `/order/customerdetails/${customerId}`,
    );
    return response.value as CustomerDetails;
  } catch (error: unknown) {
    logError('fetchCustomerInfo', error as Error);
    throw createError(error, 'Failed to fetch customer info');
  }
}

export async function fetchEdiSettingsValueData(customerId: number) {
  try {
    const response = await v3Client.get(
      `EDIConfig/EDISettingValues/${customerId}?Id=${customerId}`,
    );
    return response as unknown as Record<string, string[]>;
  } catch (error: unknown) {
    logError('fetchEditSettingsCalueData', error as Error);
    handleToast(ToastType.Error, 'Failed to fetch EDI Settings Data Value');
    throw createError(error, 'Failed to fetch EDI Settings Data Value');
  }
}

export async function fetchFeatureFlagGetCustomerFeatures(
  customerId: number,
): Promise<FeatureFlagCustomer> {
  try {
    const response = await apiClient.get(
      `FeatureFlag/GetCustomerFeatures/${customerId}`,
    );
    return response as unknown as FeatureFlagCustomer;
  } catch (error: unknown) {
    logError('fetchFeatureFlagGetCustomerFeatures', error as Error);
    handleToast(ToastType.Error, 'Failed to fetch Feature Flag Get Customer');
    throw createError(error, 'Failed to fetch Feature Flag Get Customer');
  }
}

export async function fetchTopStops(
  customerId: number,
): Promise<LastUsedTopStopModel[]> {
  try {
    const response = await apiClient.get<ApiResponse<LastUsedTopStopModel[]>>(
      `/stop/GetTop/${customerId}`,
    );
    if ('value' in response) {
      return response.value as LastUsedTopStopModel[];
    }
    throw new Error('Failed to fetch top stops');
  } catch (error: unknown) {
    logError('fetchTopStops', error as Error);
    throw createError(error, 'Failed to fetch top stops');
  }
}

export async function fetchPastStops(
  customerId: number,
): Promise<LastUsedTopStopModel[]> {
  try {
    const response = await apiClient.get<ApiResponse<LastUsedTopStopModel[]>>(
      `/stop/GetPast/${customerId}`,
    );

    if ('value' in response) {
      return response.value as LastUsedTopStopModel[];
    }

    throw new Error('Failed to fetch past stops');
  } catch (error: unknown) {
    logError('fetchPastStops', error as Error);
    throw createError(error, 'Failed to fetch past stops');
  }
}

export async function deleteLoadComment(commentId: number): Promise<boolean> {
  try {
    const response = await apiClient.delete<AxiosResponse, boolean>(
      `/Comment/Load/${commentId}`,
    );

    if (!response) {
      throw new Error('');
    }

    return response;
  } catch (error: unknown) {
    logError('deleteLoadComment', error as Error);
    throw createError(error, 'Failed to delete comment');
  }
}

export async function fetchAddressBook(): Promise<CustomerAddress[]> {
  try {
    const response: CustomerAddress[] = await v3ODataClient.get(
      `/CustomerAddresses?%24format=json&%24top=500&%24orderby=Name&%24filter=CustomerId+eq+${orderStore.order.customerId}&%24count=true`,
    );

    if ('value' in response) {
      return response.value as CustomerAddress[];
    }

    throw new Error('Failed to fetch address book');
  } catch (error: unknown) {
    logError('fetchAddressBook', error as Error);
    throw createError(error, 'Failed to fetch address book');
  }
}

export async function setToAddressBook(
  address: CustomerAddress,
): Promise<boolean> {
  try {
    const response: { isSuccess: boolean } = await apiClient.post(
      '/CustomerAddress',
      address,
    );

    return response.isSuccess;
  } catch (error: unknown) {
    logError('setToAddressBook', error as Error);
    throw createError(error, 'Failed to add address to address book');
  }
}

export async function postLoadComment(
  comment: TrackingCommentSchema,
): Promise<LoadCommentResponse> {
  try {
    const response = await apiClient.post<
      LoadCommentResponse,
      LoadCommentResponse
    >(`/Comment/Load/${comment.loadId}`, comment);
    if (response.id) {
      handleToast(ToastType.Success, 'Comment posted');
    } else {
      handleToast(ToastType.Error, 'Failed to post comment');
    }
    return response;
  } catch (error: unknown) {
    logError('postLoadComment', error as Error);
    throw createError(error, 'Failed to post load comment');
  }
}

export async function putLoadCommentVisibility(
  commentId: number,
): Promise<{ success: boolean }> {
  try {
    const response: { isSuccess: boolean } = await apiClient.put<
      AxiosResponse,
      { success: boolean }
    >(`/Comment/Load/Visibility/${commentId}`);
    if (response.isSuccess) {
      handleToast(ToastType.Success, 'Comment visibility updated');
    } else {
      handleToast(ToastType.Error, 'Failed to update comment visibility');
    }
    return response;
  } catch (error: unknown) {
    logError('putLoadCommentVisibility', error as Error);
    throw createError(error, 'Failed to upload load comment');
  }
}

export async function fetchOrderDocuments(
  orderId: string,
): Promise<DocumentModel[]> {
  try {
    const response = await apiClient.get<ApiResponse<DocumentModel[]>>(
      `/document/GetOrderDocuments/${orderId}`,
    );
    if ('value' in response) {
      return response.value as DocumentModel[];
    }
    throw new Error('Failed to fetch order documents');
  } catch (error: unknown) {
    logError('fetchOrderDocuments', error as Error);
    throw createError(error, 'Failed to fetch order documents');
  }
}

export async function fetchCoordinatesFromZip(
  addressArray: IZip[],
): Promise<ILatLon[]> {
  try {
    const response = await mapClient.post('/locations/address/batch', {
      Locations: addressArray,
    });
    return response.data as ILatLon[];
  } catch (error: unknown) {
    logError('fetchCoordinatesFromZip', error as Error);
    throw createError(error, 'Failed to fetch coordinates');
  }
}

export async function fetchCoordinatesFromAddress(
  addressArray: ICityState[],
): Promise<ILatLon[]> {
  try {
    const response = await mapClient.post('/locations/address/batch', {
      Locations: addressArray,
    });
    return response.data as ILatLon[];
  } catch (error: unknown) {
    logError('fetchCoordinatesFromAddress', error as Error);
    throw createError(error, 'Failed to fetch coordinates');
  }
}

export async function fetchEdiLogs(
  customerId: number,
  shipmentId: string | undefined,
): Promise<ediLog[]> {
  try {
    const response = await v3Client.get(
      `EDI/Transactions/{ShipmentId}/{CustomerId}?ShipmentId=${shipmentId}&CustomerId=${customerId}`,
    );
    return response as unknown as ediLog[];
  } catch (error: unknown) {
    logError('fetchEditLogs', error as Error);
    throw createError(error, 'Failed to fetch EDI transaction logs');
  }
}

export async function fetchLoadTender(
  orderId: number | string,
): Promise<ILoadTender> {
  try {
    const response = await v3Client.get(
      `EDI/GetTenderByOrder/${orderId}?id=${orderId}`,
    );
    return response as unknown as ILoadTender;
  } catch (error: unknown) {
    logError('fetchLoadTender', error as Error);
    throw createError(error, 'Failed to fetch offices');
  }
}

export async function saveOrder(
  order: IOrderViewModel,
): Promise<IOrderViewModel> {
  try {
    const updatedOrder: IOrderViewModel =
      order.id === 0
        ? await apiClient.post('order', order)
        : await apiClient.put('order', order);
    const { type, message } = updatedOrder.id
      ? { type: ToastType.Success, message: 'Order Saved' }
      : {
          type: ToastType.Error,
          message: 'Failed to create Order please try again',
        };

    // We shouldn't handle toast messages here. The caller should know exactly
    // what's happening.
    handleToast(type, message);

    return updatedOrder as unknown as IOrderViewModel;
  } catch (error: unknown) {
    logError('saveOrder', error as Error);
    throw createError(error, 'Failed to save order');
  }
}

export async function makeCreditCardPayments(
  orderId: number,
  paymentDetails: ICreditCardPayment,
) {
  try {
    await apiClient.post(`Email/PayWithCreditCard/${orderId}`, paymentDetails);
    handleToast(
      ToastType.Success,
      'Customer Sent Credit Card Payments Information',
    );
  } catch (error: unknown) {
    logError('makeCreditCardPayments', error as Error);
    throw createError(error, 'Failed to send Credit Card Payments to Customer');
  }
}

export async function fetchCarrierDetail(Id: number): Promise<Carrier> {
  try {
    const response = await apiClient.get(`carrier/details/${Id}`);

    return response as unknown as Carrier;
  } catch (error: unknown) {
    logError('fetchCarrierDetail', error as Error);
    throw createError(error, 'Failed to fetch carrier details info');
  }
}

export async function fetchCarrierFeatures(
  id: number,
): Promise<CarrierFeatures | undefined> {
  try {
    const response = await apiClient.get(
      `FeatureFlag/GetCarrierFeatures/${id}`,
    );
    return response as unknown as CarrierFeatures;
  } catch (error: unknown) {
    logError('fetchCarrierFeatures', error as Error);

    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to fetch carrier features: ${error.message}`,
      );
    }
  }
}

export async function checkCarrierConditional(
  mc: string | undefined,
  dot: string | undefined,
): Promise<CheckCarrierConditionalApiResponse | undefined> {
  try {
    const response: CheckCarrierConditionalApiResponse = await v3Client.get(
      `Carrier/CheckConditional?MC=${mc}&DOT=${dot}`,
    );
    return response as unknown as CheckCarrierConditionalApiResponse;
  } catch (error: unknown) {
    logError('checkCarrierConditional', error as Error);

    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to checkCarrierConditional info: ${error.message}`,
      );
    }
  }
}

export async function carrierUpdateInsurance(mc: string, dot: string) {
  try {
    const response: V4ApiResponse<CarrierInsuranceDetail> =
      await apiClient.post(`carrier/updateinsurance?mc=${mc}&dot=${dot}`);
    return response;
  } catch (error: unknown) {
    logError('carrierUpdateInsurance', error as Error);
    throw createError(error, 'Failed to update carrier insurance');
  }
}

export async function addNewContactToCarrier(
  contact: Record<string, string | number | boolean | null>,
) {
  try {
    const response = await apiClient.post('Carrier/addcarriercontact', contact);
    return response.value as unknown as Contact;
  } catch (error: unknown) {
    logError('addNewContactToCarrier', error as Error);
    throw createError(error, 'Failed to add contact info');
  }
}

export async function addFlag(
  option: Partial<FlagViewModel>,
): Promise<FlagViewModel> {
  try {
    const response = await apiClient.post('flag/CreateFlag', option);

    return response as unknown as FlagViewModel;
  } catch (error: unknown) {
    logError('addFlag', error as Error);
    throw createError(error, 'Failed to add flag');
  }
}

export async function putFalloutCount(carrierId: number) {
  try {
    const response: boolean = await apiClient.put(
      `carrier/fallout/${carrierId}`,
    );

    if (!response) {
      throw new Error('Failed to increment fallout count');
    }

    return response;
  } catch (error: unknown) {
    logError('putFalloutCount', error as Error);
    throw createError(error, 'Failed to add carrier details info');
  }
}

export async function fetchOfficeGroups(id: number) {
  try {
    const response = await apiClient.get(`Office/group/names/${id}`);
    return response as unknown as Record<string, string | number>[];
  } catch (error: unknown) {
    logError('fetchOfficeGroups', error as Error);
    throw createError(error, 'Failed to fetch office groups');
  }
}

export async function fetchUserInfo(Id: number) {
  try {
    const response = await apiClient.get(`User/userinfo/${Id}`);
    return response.value as unknown as CustomerContactViewModel;
  } catch (error: unknown) {
    logError('fetchUserInfo', error as Error);
    throw createError(error, 'Failed to fetch user info');
  }
}
export async function fetchUserInfoUserModel(Id: number) {
  try {
    const response = await apiClient.get(`User/userinfo/${Id}`);
    return response.value as unknown as UserInfoModel;
  } catch (error: unknown) {
    logError('fetchUserInfoUserModel', error as Error);
    throw createError(error, 'Failed to fetch user info');
  }
}

export async function updateFlag(
  option?: FlagViewModel,
): Promise<FlagViewModel> {
  try {
    const response = await apiClient.put('flag', option);

    return response as unknown as FlagViewModel;
  } catch (error: unknown) {
    logError('updateFlag', error as Error);
    throw createError(error, 'Failed to update flag');
  }
}

export async function deleteFlag(id: number) {
  try {
    const response = await apiClient.delete<ApiResponse<boolean>>(`flag/${id}`);
    if ('value' in response) {
      return response.value as boolean;
    }
    return false;
  } catch (error: unknown) {
    logError('deleteFlag', error as Error);
    throw createError(error, 'Failed to delete flag');
  }
}

export async function fetchProductCatalog(queryParams: {
  $format?: string;
  $top?: number;
  $skip?: number;
  $orderby?: string;
  $count?: boolean;
  $filter?: string;
}) {
  try {
    const response: ODataApiResponse = await v3ODataClient.get('LTLCatalogs', {
      params: queryParams,
    });
    if ('value' in response) {
      return {
        data: response.value,
        count: response['@odata.count'],
      };
    }
    throw new Error('Failed to fetch product catalog');
  } catch (error: unknown) {
    logError('fetchProductCatalog', error as Error);
    throw createError(error, 'Failed to fetch product catalog');
  }
}

export async function saveToLTLCatalog(
  item: PickUpDropOffItem,
  customerId: number,
) {
  try {
    const response = await apiClient.post('/LTLCatalog', {
      customerId: customerId,
      name: item.description,
      description: item.description,
      class: String(item.class),
      nmfc: item.nmfc,
      hazmat: item.hazmat,
      lengthInches: item.lengthInch,
      widthInches: item.widthInch,
      heightInches: item.heightInch,
      ltlCatalogItemHazmatDetail: item.loadItemHazmatDetail,
    });
    handleToast(ToastType.Success, 'Item saved to LTL Catalog');
    // NOTE: response is not used anywhere add a notification later
    return response;
  } catch (error) {
    logError('saveToLTLCatalog', error as Error);
    const err = createError(error, 'Failed to save to LTL Catalog');
    handleToast(ToastType.Error, err.message);
  }
}

export async function postOrderComment(
  payload: Record<string, string | number | null>,
) {
  try {
    const response = await apiClient.post('Order/saveordercomments', payload);
    return response.value as unknown as Comments;
  } catch (error: unknown) {
    logError('postOrderComment', error as Error);
    throw createError(error, 'Failed to fetch user info');
  }
}

export async function deleteOrderComment(commentId: number) {
  try {
    const res: V4ApiResponse<null> = await apiClient.delete(
      `Order/deleteComment/${commentId}`,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    logError('deleteOrderComment', error as Error);

    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to delete order comment: ${error.message}`,
      );
    }
  }
}

export async function voidLoad(loadId: number) {
  try {
    const response: { isSuccess: boolean } = await apiClient.post(
      `Order/DeleteLoad/${loadId}`,
    );
    if (response.isSuccess) {
      updateLoadPropertyAtIndex({ status: 'Void' });
      handleToast(ToastType.Success, 'Load Voided');
    }
  } catch (error: unknown) {
    //V3 error handling was moved to apiClient.ts
    logError('voidLoad', error as Error);
  }
}

export async function resendEDI(id: string | undefined) {
  try {
    await v3Client.post(`EDI/Resend/${id}`, { id });
  } catch (error: unknown) {
    logError('resendEDI', error as Error);
    throw createError(error, 'Failed to resend the EDI');
  }
}

export async function fetchEdiChangeLogs(
  id: string | undefined,
): Promise<IOrderViewModel> {
  try {
    const response = await v3Client.get(`EDI/GetChangeDetails/${id}?id=${id}`);
    return response as unknown as IOrderViewModel;
  } catch (error: unknown) {
    logError('fetchEdiChangeLogs', error as Error);
    throw new Error(
      `Failed to fetch Edi transaction logs: ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function voidVendor(vendorId: number, index: number) {
  try {
    const response: { isSuccess: boolean; Message: string } =
      await apiClient.delete(`VendorContract/${vendorId}`);
    if (response.isSuccess) {
      removeVendorFromOrder(index);
      handleToast(ToastType.Success, 'Vendor Voided');
      return true;
    }
  } catch (error: unknown) {
    logError('voidVendor', error as Error);
    return false;
    //V3 error handling was moved to apiClient.ts
  }
}

export async function unlinkEDITender(shipmentId: string, orderId: string) {
  try {
    await apiClient.post('order/UnlinkLoadTender', {
      shipmentId: shipmentId,
      orderId: orderId,
    });
  } catch (error: unknown) {
    logError('unlinkEDITender', error as Error);
    throw createError(error, 'Failed to unlink the EDI');
  }
}

export async function fetchAvailableServices(): Promise<availableServices[]> {
  try {
    const response = await apiClient.get('posting/getavailableservices');

    if ('value' in response) {
      return response.value as availableServices[];
    }
    throw new Error('Failed to fetch avilable services');
  } catch (error: unknown) {
    logError('fetchAvailableServices', error as Error);
    throw createError(error, 'Failed to fetch available services');
  }
}

export async function createPostLoad(value: IPostLoad): Promise<IPostLoad> {
  try {
    const postLoadResponse: {
      data: { id: number };
      errors: [];
      partialSuccess: boolean;
    } = await apiClient.post('/Posting', value);
    if (postLoadResponse.partialSuccess) {
      const errors = postLoadResponse.errors.map((error: string) => {
        if (error.includes('TruckStop') && error.includes('password')) {
          return 'TruckStop Password Is Invalid';
        }
        return error;
      });
      handleToast(
        ToastType.Caution,
        `Posting Load Partial Success: ${errors.join('\n')}`,
      );
    } else {
      handleToast(ToastType.Success, 'Posting Load Success');
    }
    return postLoadResponse.data as unknown as IPostLoad;
  } catch (error: unknown) {
    logError('createPostLoad', error as Error);
    throw createError(error, 'Failed to make create post load');
  }
}

export async function updatePostLoad(
  value: IManagePostLoad,
): Promise<IManagePostLoad> {
  try {
    const postLoadResponse: IManagePostLoad = await apiClient.put(
      '/Posting',
      value,
    );
    const errors = postLoadResponse.errors;
    if (errors.length > 0) {
      handleToast(ToastType.Error, `${errors.join('\n')}`);
    } else {
      handleToast(ToastType.Success, 'Posted!');
    }
    return postLoadResponse as unknown as IManagePostLoad;
  } catch (error: unknown) {
    logError('updatePostLoad', error as Error);
    throw createError(error, 'Failed to make update post load');
  }
}

export async function fetchPosting(postId: number): Promise<IManagePostLoad> {
  try {
    const response = await apiClient.get(`posting/${postId}`);

    return response as unknown as IManagePostLoad;
  } catch (error: unknown) {
    logError('fetchPosting', error as Error);
    throw createError(error, 'Failed to fetch posting');
  }
}

export async function requestChecks(efsCheck: {
  amount: number;
  comment: string;
  lineItemId: number;
}) {
  try {
    const response: { isSuccess: boolean } = await apiClient.post(
      'load/RequestCheck',
      efsCheck,
    );
    if (response.isSuccess) {
      setOrderStore(
        'order',
        'loads',
        orderStore.activeTab.index,
        'lineItems',
        (lineItem) => lineItem.id === efsCheck.lineItemId,
        'status',
        'Requested',
      );
      handleToast(ToastType.Success, 'Check Requested');
    } else {
      handleToast(ToastType.Error, 'Failed to request check');
    }
    return response;
  } catch (error: unknown) {
    logError('requestChecks', error as Error);
    throw createError(error, 'Failed to fetch posting');
  }
}

export async function fetchPlaylists(): Promise<Playlist[] | undefined> {
  try {
    const response = await apiClient.get('playlist');
    if ('value' in response) {
      return response.value as Playlist[];
    }
    throw new Error('Failed to fetch playlist');
  } catch (error: unknown) {
    logError('fetchPlaylists', error as Error);
    handleToast(ToastType.Error, JSON.stringify(error));
  }
}

export async function createPlaylist(
  playlist: Playlist,
): Promise<Playlist | undefined> {
  try {
    const response = await apiClient.post('playlist', playlist);
    if ('value' in response) {
      return response.value as Playlist;
    }
    throw new Error('Failed to create playlist');
  } catch (error: unknown) {
    logError('createPlaylist', error as Error);
    throw createError(error, 'Failed to create playlist');
  }
}

export async function addCarrierToPlaylist(carrierPlaylistPayload: {
  CarrierId: number;
  PlaylistId: number;
  Selected: boolean;
}) {
  try {
    const response: { isSuccess: boolean } = await apiClient.post(
      'playlist/addcarrier',
      {
        ...carrierPlaylistPayload,
      },
    );
    if (response.isSuccess) {
      handleToast(ToastType.Success, 'Carrier Added to Playlist');
    } else {
      handleToast(ToastType.Error, 'Failed to add carrier to playlist');
    }
    return response;
  } catch (error: unknown) {
    logError('addCarrierToPlaylist', error as Error);
    throw createError(error, 'Failed to fetch playlist');
  }
}

export async function enableTracking(
  data: TrackingData,
): Promise<TrackingResponse | undefined> {
  try {
    const response = await apiClient.post('/Tracking/Begin', data);
    const apiResponse = response as unknown as ApiResponse<TrackingResponse>;

    if (Boolean(apiResponse.value.success)) {
      return apiResponse.value;
    }

    throw new Error('There was an unknown error initiating tracking');
  } catch (error: unknown) {
    logError('enableTracking', error as Error);
    throw createError(error, 'Failed to enable tracking');
  }
}

export async function disableTracking(loadId: number) {
  const res: { isSuccess: boolean } = await apiClient.post(
    `/Tracking/${loadId}`,
  );
  if (res.isSuccess) {
    handleToast(ToastType.Success, 'Load Tracking Disabled');
    setOrderStore('order', 'loads', orderStore.activeTab.index, {
      trackingEnabled: false,
      trackingServiceId: null,
    });
  }
}

export async function rateCarrier({
  carrierId,
  loadId,
  rating,
  comment,
}: RatePayloadType) {
  try {
    const response = await apiClient.post('Carrier/AddCarrierRating', {
      id: carrierId,
      loadId: loadId,
      rating: rating,
      comment: comment,
    });
    return response as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    logError('rateCarrier', error as Error);
    throw createError(error, 'Failed to add rate');
  }
}

export const sendEmail = async (
  from: string[],
  sender: string,
  to: string[],
  subject: string,
  message: string,
) => {
  try {
    const resp = await apiClient.post('Email/Send', {
      From: sender,
      CC: from,
      Body: message,
      Subject: subject,
      To: to,
    });
    handleToast(ToastType.Success, 'Email sent to customer');
    return (resp as unknown as { success: boolean }).success;
  } catch (e) {
    logError('sendEmail', e as Error);
    handleToast(ToastType.Error, 'Unable to send email');
    return false;
  }
};

export const sendText = async (to: string, message: string) => {
  try {
    const resp = await apiClient.post('Email/SendText', {
      number: to,
      message: message,
    });
    handleToast(ToastType.Success, 'Text message sent to Accounting');
    return (resp as unknown as { success: boolean }).success;
  } catch (e) {
    logError('sendText', e as Error);
    handleToast(
      ToastType.Error,
      `Unable to send text to ${formatPhoneNumber(to)}`,
    );
    return false;
  }
};

export async function shareAndUnShareLoadCapacity(
  payload: ShareUnShareCapacityPayload,
) {
  try {
    const response = await apiClient.post('load/ShareCapacity/policy', payload);
    return response as unknown as {
      error: string[];
      isSuccess: boolean;
      value: ShareCapacityResult;
      isFailure: boolean;
    };
  } catch (error: unknown) {
    logError('shareAndUnshareLoadCapacity', error as Error);
    throw createError(error, 'Failed to share load capacity');
  }
}
export async function getNearbyCarriers(
  payload: NearbyCarrierRequestViewModel,
) {
  try {
    if (payload.radius === undefined) {
      payload.radius = 60;
    }
    const response = await apiClient.post<NearbyCarriersReturn[]>(
      'order/load/nearbycarriers',
      payload,
    );

    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    logError('getNearbyCarriers', error as Error);
    throw createError(error, 'Failed to fetch nearby carriers');
  }
}
export async function fetchLtlReferenceNumbers(loadId: number) {
  try {
    const response = await apiClient.get<LTLTrackingData>(
      `/Ltl/GetReferenceNumbers/${loadId}`,
    );

    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    logError('getLtlReferenceNumbers', error as Error);
    throw new Error(`Failed to fetch LTL Reference Numbers: ${error}`);
  }
}

export async function fetchLtlQuoteParametersByQuoteId(
  quoteId: string,
): Promise<LtlQuoteParametersViewModel> {
  try {
    const response = await apiClient.get<{
      value?: LtlQuoteParametersViewModel;
    }>(`/Ltl/GetQuoteParametersByQuoteId/${quoteId}`);

    if ('value' in response) {
      return response.value as LtlQuoteParametersViewModel;
    }
    throw new Error(
      'Invalid response format: cannot get value of Quote from response.',
    );
  } catch (error: unknown) {
    logError('GetQuoteParametersByquoteId', error as Error);
    throw new Error(
      `Failed to fetch LTL Quote Parameters for quote ${quoteId}: ${String(
        error,
      )}`,
    );
  }
}

export async function fetchLtlQuoteParametersByLoadId(
  loadId: number,
): Promise<LtlQuoteParametersViewModel> {
  try {
    const response = await apiClient.get<{
      value?: LtlQuoteParametersViewModel;
    }>(`/Ltl/GetQuoteParametersByLoadId/${loadId}`);

    if ('value' in response) {
      return response.value as LtlQuoteParametersViewModel;
    }
    throw new Error(
      'Invalid response format: cannot get value of Quote from response.',
    );
  } catch (error: unknown) {
    logError('GetQuoteParametersByLoadId', error as Error);
    throw new Error(
      `Failed to fetch LTL Quote Parameters for load ${loadId}: ${String(
        error,
      )}`,
    );
  }
}

export async function fetchLtlQuoteByLoadId(
  loadId: number,
): Promise<NewLtlQuoteViewModel> {
  try {
    const response = await apiClient.get<{
      value?: NewLtlQuoteViewModel;
    }>(`/Ltl/GetLtlQuoteByLoadId/${loadId}`);

    if ('value' in response) {
      return response.value as NewLtlQuoteViewModel;
    }
    throw new Error(
      'Invalid response format: cannot get value of Quote from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetLtlQuoteByLoadId',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Quote for loadId ${loadId}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function fetchLtlQuoteById(
  id: string,
): Promise<NewLtlQuoteViewModel> {
  try {
    const response = await apiClient.get<{
      value?: NewLtlQuoteViewModel;
    }>(`/Ltl/GetLtlQuoteById/${id}`);

    if ('value' in response) {
      return response.value as NewLtlQuoteViewModel;
    }
    throw new Error(
      'Invalid response format: cannot get value of Quote from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetLtlQuoteById',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Quote for Id ${id}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function fetchLtlQuoteLineItems(
  quoteId: number,
): Promise<LtlQuoteLineItemViewModel[]> {
  try {
    const response = await apiClient.get<{
      value?: LtlQuoteLineItemViewModel[];
    }>(`/Ltl/GetQuoteLineItems/${quoteId}`);

    if ('value' in response) {
      return response.value as LtlQuoteLineItemViewModel[];
    }
    throw new Error(
      'Invalid response format: cannot get value of Line Items from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetQuoteLineItems',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Quote Line Items for quoteId ${quoteId}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function fetchLtlQuoteLoadItems(
  quoteParametersId: number,
): Promise<LtlQuoteLoadItemViewModel[]> {
  try {
    const response = await apiClient.get<{
      value?: LtlQuoteLoadItemViewModel[];
    }>(`/Ltl/GetQuoteLoadItems/${quoteParametersId}`);

    if ('value' in response) {
      return response.value as LtlQuoteLoadItemViewModel[];
    }
    throw new Error(
      'Invalid response format: cannot get value of Load Items from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetQuoteLoadItems',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Quote Load Items for quote parameters Id ${quoteParametersId}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function fetchLtlQuoteAccessorials(
  quoteParametersId: number,
): Promise<LtlQuoteAccessorialViewModel[]> {
  try {
    const response = await apiClient.get<{
      value?: LtlQuoteAccessorialViewModel[];
    }>(`/Ltl/GetQuoteAccessorials/${quoteParametersId}`);

    if ('value' in response) {
      return response.value as LtlQuoteAccessorialViewModel[];
    }
    throw new Error(
      'Invalid response format: cannot get value of Accessorials from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetQuoteAccessorials',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Quote Accessorials for quote parameters Id ${quoteParametersId}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function fetchLtlQuoteInsurance(
  quoteParametersId: number,
): Promise<FalveyInsuranceQuote> {
  try {
    const response = await apiClient.get<{
      value?: FalveyInsuranceQuote;
    }>(`/Ltl/GetInsuranceQuote/${quoteParametersId}`);

    if ('value' in response) {
      return response.value as FalveyInsuranceQuote;
    }
    throw new Error(
      'Invalid response format: cannot get value of Insurance from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetInsuranceQuote',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Insurance Quote for quote parameters Id ${quoteParametersId}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function fetchLtlQuoteTerminals(
  quoteId: string,
): Promise<LtlTerminalInfoViewModel> {
  try {
    const response = await apiClient.get<{
      value?: LtlTerminalInfoViewModel;
    }>(`/Ltl/GetQuoteTerminals/${quoteId}`);

    if ('value' in response) {
      return response.value as LtlTerminalInfoViewModel;
    }
    throw new Error(
      'Invalid response format: cannot get value of Terminals from response.',
    );
  } catch (error: unknown) {
    logError(
      'GetQuoteTerminals',
      error instanceof Error ? error : new Error(String(error)),
    );
    throw new Error(
      `Failed to fetch LTL Terminal Info for quote Id ${quoteId}. ${
        error instanceof Error ? error.message : error
      }`,
    );
  }
}

export async function vendorEFSCheckRequest(payload: { lineItemId: number }) {
  try {
    const response = await apiClient.post(
      'VendorContract/RequestCheck',
      payload,
    );
    handleToast(ToastType.Success, 'Vendor EFS Check Requested Successfully');
    return response as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    logError('vendorEFSCheckRequest', error as Error);
    throw new Error('Vendor EFS Check Request failed');
  }
}

export async function getFlagComment(commentId: number) {
  try {
    const response = await apiClient.get(`Flag/Comment/${commentId}`);
    return response.value as unknown as Record<string, string | number>[];
  } catch (error: unknown) {
    logError('getFlagComment', error as Error);
    throw createError(error, 'Failed to fetch comment on flag');
  }
}

export async function addFlagComment(
  payload: AddFlagCommentPayload,
  cb?: () => void,
) {
  try {
    const response = await v3Client.get(`Flag/Comment/${payload.id}`, {
      params: payload,
    });
    return response.data as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    logError('addFlagComment', error as Error);
    throw createError(error, 'Failed to add comment on flag');
  } finally {
    cb && cb();
  }
}

export async function deleteFlagComment(commentId: number) {
  try {
    const response = await apiClient.delete(
      `flag/DeleteOrderFlagComment/${commentId}`,
    );
    return response as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    logError('deleteFlagComment', error as Error);
    throw createError(error, 'Failed to delete comment on flag');
  }
}

export async function fetchEDIStatusCodes(
  id: number,
): Promise<EDIStatusCodesApiResponse> {
  try {
    const response = await apiClient.get(`edi/StatusCodes/${id}`);
    return response.value as unknown as EDIStatusCodesApiResponse;
  } catch (error: unknown) {
    logError('fetchEDIStatusCodes', error as Error);
    throw createError(error, 'Failed to fetch EDI status codes');
  }
}

export async function sendEDIUpdate(
  id: number,
  payload: LoadEdiStatusUpdatePayload,
): Promise<Record<string, string | number | boolean>> {
  try {
    const response = await v3Client.post(`EDI/SendUpdate/${id}`, payload);
    return response as unknown as Record<string, string | number | boolean>;
  } catch (error: unknown) {
    logError('sendEDIUpdate', error as Error);
    throw createError(error, 'Failed to send EDI Update');
  }
}

export async function calculateMileage(
  stops: LastUsedTopStopModel[] | undefined,
  params: string | number | null,
) {
  try {
    const geoCoordinates = await getGeoCoordinates(stops);
    const formatted = Array.isArray(geoCoordinates)
      ? geoCoordinates.map(
          (c: {
            coords: {
              lat: string;
              lon: string;
            };
          }) => {
            return `${c.coords.lon},${c.coords.lat}`;
          },
        )
      : [];
    const shortMiles: Record<string, string | number>[] =
      await pcMilerClient.get(
        `route/routeReports?reports=CalcMiles&stops=${formatted.join(
          ';',
        )}&dataVersion=${params !== null ? params : ''}&routeType=Shortest`,
      );

    const practicalMiles: Record<string, string | number>[] =
      await pcMilerClient.get(
        `route/routeReports?reports=CalcMiles&stops=${formatted.join(
          ';',
        )}&dataVersion=${params !== null ? params : ''}&routeType=Practical`,
      );

    if (Array.isArray(shortMiles) && Array.isArray(practicalMiles)) {
      return {
        shortMiles: shortMiles[0].tMiles,
        practicalMiles: practicalMiles[0].tMiles,
      };
    }
  } catch (error: unknown) {
    logError('calculateMilage', error as Error);
    throw createError(error, 'Failed to get mileage');
  }
}

export async function getGeoCoordinates(
  stops: LastUsedTopStopModel[] | undefined,
) {
  try {
    const params: PcMilerResponse[] | undefined = stops?.map((stop) => {
      return stop.zip
        ? { address: { zip: stop.zip } }
        : { address: { city: stop.city, state: stop.state } };
    });

    const response: PcMilerResponse[] = await pcMilerClient.post(
      'locations/address/batch',
      {
        Locations: params,
      },
    );

    //For error handling start
    response.forEach((item, index) => {
      const filteredErrors = (item.errors || []).filter(
        (error) => error.type !== 0,
      );
      if (params) params[index].errors = filteredErrors;
    });

    (params || []).forEach((error) => {
      if (error.address.zip != null) {
        if (error.errors && error.errors.length > 0) {
          error.errors[0].description +=
            ' for ' +
            (Boolean(error.address.zip)
              ? error.address.zip
              : `${error.address.state}`);
        }
      }
    });
    const errors = params?.flatMap((param) => param.errors);
    //error handling end

    // TODO: need to handle errors in UI
    if (errors && errors.length < 1) {
      return response as unknown as Record<string, string | number>;
    }
  } catch (error: unknown) {
    logError('getGeoCoordinates', error as Error);
    throw createError(error, 'Failed to get mileage');
  }
}
export async function voidOrder(orderId: number) {
  try {
    const response: { success: boolean; message: string } =
      await apiClient.post(`Order/Void/${orderId}`);

    handleToast(
      Boolean(response.success) ? ToastType.Success : ToastType.Error,
      Boolean(response.success) ? 'Order Voided' : response.message,
    );

    return response as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    logError('voidOrder', error as Error);

    if (error instanceof AxiosError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      handleToast(ToastType.Error, `${error.response?.data?.ExceptionMessage}`);
    }
  }
}

export const disputeFlag = async (flagId: number, name: string) => {
  try {
    const res = await apiClient.post(`Flag/Dispute/${flagId}?name=${name}`);
    return res as unknown as ILoadComments | null;
  } catch (e) {
    logError('disputeFlag', e as Error);
    throw createError(e, 'Failed to set appointment reminder');
  }
};

export async function setAppointmentReminder(
  payload: AppointmentReminderModel,
) {
  try {
    const response = await apiClient.post('Reminder', payload);
    if (!isEmpty(response)) {
      handleToast(
        ToastType.Success,
        'Setting appointment reminder Successfully',
      );
    } else {
      handleToast(ToastType.Error, 'Setting appointment reminder Failed');
    }
    return response as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    logError('setAppointmentReminder', error as Error);
    throw createError(error, 'Failed to set appointment reminder');
  }
}

export async function getGoogleGeocode(payload: GeocodeModel) {
  try {
    const response = await apiClient.post<GeocodeModel>(
      'Location/Geocode',
      payload,
    );
    return response.value as unknown as GeocodeModel;
  } catch (error: unknown) {
    logError('getGoogleGeocode', error as Error);
    throw createError(error, 'Failed to get geocode');
  }
}

export async function requestPickup(payload: ILoadEditViewModel) {
  try {
    const response = await apiClient.post('ltl/submitshipment', payload);
    const result = response as unknown as {
      submitShipmentResult: SubmitShipmentResult;
      commitQuoteResult: CommitQuoteResult;
    };
    if (result.submitShipmentResult.message === 'Success') {
      const index = orderStore.activeTab.index;
      setOrderStore('order', 'loads', index, 'status', 'Dispatched');
      handleToast(ToastType.Success, 'Pickup request submitted');
      return result;
    }
    handleToast(ToastType.Error, 'Error submit pickup');
    return result;
  } catch (error: unknown) {
    logError('requestPickup', error as Error);
    throw createError(error, 'Failed to request pickup');
  }
}

export async function postNextCheckCall(
  loadId: number,
  nextCheckCallDateUtc: string,
): Promise<boolean> {
  try {
    const response = await apiClient.post('load/nextcheckcall', {
      loadId,
      nextCheckCallDateUtc,
    });

    return response as unknown as boolean;
  } catch (error: unknown) {
    logError('postNextCheckCall', error as Error);
    throw createError(error, 'Failed to update next check call');
  }
}

export async function getCustomerContact(id: number) {
  try {
    const response: ICustomerContact[] = await v3Client.get(
      `/Contact/Customer/${id}`,
    );
    return response;
  } catch (error) {
    logError('getCustomerContact', error as Error);

    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Customer Contact');
      throw new Error(`Failed to Customer Contact: ${error.message}`);
    }
  }
}

export async function fetchOrderTemplates() {
  try {
    const response: OrderTemplate[] = await v3Client.get('Order/GetTemplates');
    return response;
  } catch (error: unknown) {
    logError('fetchOrderTemplates', error as Error);
    handleToast(ToastType.Error, 'Failed to get order templates');
  }
}

export async function deleteOrderTemplateService(id: number) {
  try {
    const resp = await v3Client.post(`Order/DeleteTemplate/${id}`);
    if ('success' in resp && Boolean(resp.success)) {
      handleToast(ToastType.Success, 'Order template deleted');
      return resp.success;
    }
  } catch (error: unknown) {
    logError('deleteOrderTemplateService', error as Error);
    handleToast(ToastType.Error, 'Failed to delete order template');
  }
}

export async function saveOrderTemplateCall(
  templateName: string,
  isPublic: boolean,
  template: Partial<IOrderViewModel>,
  id?: number,
  cb?: () => void,
) {
  try {
    const resp = await v3Client.post('Order/SaveTemplate', {
      name: templateName,
      public: isPublic,
      template: template,
      templateId: id ?? 0,
    });
    if ('success' in resp && Boolean(resp.success)) {
      handleToast(ToastType.Success, 'Order template saved');
      return resp.success;
    }
  } catch (error: unknown) {
    logError('saveOrderTemplateCall', error as Error);
    handleToast(ToastType.Error, 'Failed to save order template');
    return false;
  } finally {
    cb && cb();
  }
}

export async function fetchOrderTemplateCall(templateId: number) {
  try {
    const resp: { value: IOrderViewModel } = await apiClient.get(
      `Order/LoadTemplate/${templateId}`,
    );
    return resp.value;
  } catch (error: unknown) {
    logError('fetchOrderTemplateCall', error as Error);
    handleToast(
      ToastType.Error,
      `Failed to fetc order template data for templateId: ${templateId}`,
    );
    throw new Error(
      'Failed to fetc order template data for templateId: ${templateId}',
    );
  }
}

export async function postCopiedOrder(
  id: string,
  copiedOrder: IOrderViewModel,
) {
  try {
    const payload = { id, order: copiedOrder };
    const response = await apiClient.post('/order/StoreCopyOrder', payload);
    printLog(response);
  } catch (error) {
    logError('postCopiedOrder', error as Error);
    handleToast(ToastType.Error, 'Failed to copy order');
    throw new Error(`Failed to copy order for id: ${id}`);
  }
}

export async function fetchLoadSearchData(queryParams: { $filter?: string }) {
  try {
    const response = await v3ODataClient.get('LoadSearch', {
      params: queryParams,
    });
    if ('value' in response) {
      return {
        data: response.value as LoadSearch[],
      };
    }
    throw new Error('Failed to fetch Load Data');
  } catch (error: unknown) {
    logError('fetchLoadSearchData', error as Error);
    throw createError(error, 'Failed to fetch load data');
  }
}

export async function fetchRevenueSearchData(queryParams: {
  $filter?: string;
}) {
  try {
    const response = await v3ODataClient.get('Orders', {
      params: queryParams,
    });

    if ('value' in response) {
      return {
        data: response.value as RevenueSearchData[],
      };
    }
  } catch (error: unknown) {
    logError('fetchRevenueSearchData', error as Error);
    throw createError(error, 'Failed to fetch Revenue Data');
  }
}

export async function fetchPricingForPosting(
  payload: FetchPricingForPostingPayload,
) {
  try {
    const response: FetchPricingForPostingResponse = await v3Client.post(
      '/Pricing',
      payload,
    );
    return response;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch pricing for posting');
  }
}
