/* Acceptable Format for TimepickerV2 value is '2021-09-01T12:00:00.000'
to make it work properly
and onChange it will return the same format.
*/
import { TextInput } from '@components/forms';
import { StyledProps } from '@suid/material';
import { DateTime } from 'luxon';
import { JSXElement, mergeProps } from 'solid-js';
import './index.css';

type TimepickerProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string | JSXElement;
  error?: string | string[] | null;
  variant?: 'outlined' | 'filled' | 'standard';
  class?: string;
  sxProps?: StyledProps;
  disabled?: boolean;
  isUTC?: boolean;
  dataTestId?: string;
};

export const TimePicker = (props: TimepickerProps) => {
  props = mergeProps(
    {
      variant: 'outlined',
      helperText: ['Invalid time'],
      fullWidth: true,
    },
    props,
  );

  const formatTime = (value: string) => {
    const date = DateTime.fromISO(value);

    return date.toFormat('T');
  };

  const reverseFormatTime = (time: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if ((time?.trim() ?? '').length === 0) {
      return '';
    }

    const dateParts = props.value
      ? props.value.split('T')
      : DateTime.now().toISO().split('T');
    return `${dateParts[0]}T${time ? time : ''}`;
  };

  return (
    <TextInput
      InputLabelProps={{
        shrink: true,
      }}
      type="time"
      {...props}
      value={formatTime(props.value)}
      inputProps={{
        class: 'timepicker-custom',
      }}
      dataTestId={props.dataTestId}
      onChange={(value) => {
        props.onChange(reverseFormatTime(value as string));
      }}
      label={props.label}
      error={
        props.error !== undefined &&
        props.error !== null &&
        props.error.length > 0
          ? props.error[0]
          : ''
      }
    />
  );
};
