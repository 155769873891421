import { createStore } from 'solid-js/store';

import { ApiCallHistory, ApiStore } from './types';

export const [apiCallsStore, setApiCallsStore] = createStore<ApiStore>({
  apiCalls: [],
});

export const addApiCall = (apiCallHistory: ApiCallHistory) => {
  setApiCallsStore('apiCalls', (apiCalls) => {
    const updatedApiCalls = [...apiCalls, apiCallHistory];
    return updatedApiCalls.slice(-10); // Keep only the last 10 calls
  });
};
