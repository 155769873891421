import * as yup from 'yup';

export const factoringCompanyDetailsSchema = yup.object().shape({
  name: yup
    .string()
    // eslint-disable-next-line quotes
    .max(50, `Factor name can't exceed 50 characters.`)
    .required('Name is required'),
  status: yup.string().required('Status is required'),
  mailingAddress1: yup.string().required('Mailing Address is required'),
  mailingCity: yup.string().required('City is required'),
  mailingState: yup.string().required('State is required'),
  mailingZip: yup.string().required('Zip is required'),
  remitTo: yup.string().email('Invalid email').nullable(),
});
